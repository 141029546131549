import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import HeaderBg from '../../assets/Images/header-bg.jpg';
import Logo from '../../assets/Images/logo.svg';


export const Header = ({ children }) => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${HeaderBg})`,
        backgroundSize: "cover"
      }}
    >
      <Grid container sx={{ py: 3 }}>
        <Grid item lg={6} md={6} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <img src={Logo} alt="logo" width={134} height={78} />
        </Grid>
        <Grid item lg={6} md={6} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Typography sx={{ color: "white", fontSize: { xs: 14, sm: 28 }, fontWeight: 500, fontFamily: "Roboto, sans-serif" }}>{children}</Typography>
        </Grid>
      </Grid>
    </Box >
  );
};
