import React from 'react';
import CurrencyInput from 'react-currency-input-field';

export default function RRCurrencyInput({ inputProps, ...rest }) {
    const { locale, onChange, currency, value } = rest;

    return (

        <CurrencyInput
            value={value}
            intlConfig={{ locale, currency }}
            decimalsLimit={2}
            onValueChange={onChange}
            {...inputProps}
        />

    )
}