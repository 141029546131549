// ** React Imports
import { useState } from "react";






import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled, useTheme } from "@mui/material/styles";


// Tab

import { Typography } from "@mui/material";




import { useSearchParams, useNavigate } from "react-router-dom";


// ** Styled Components
const BoxWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: '90vw'
  }
}))

const InvalidLinkPage = () => {


  const navigate = useNavigate()

  
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const org_id = searchParams.get("org_id");
  const client_id = searchParams.get("client_id");
  const timestamp = searchParams.get("timestamp");
  const signature = searchParams.get("signature");

  const hidden = useMediaQuery(theme.breakpoints.down("md"));

//   useEffect(() => {
//     if (
//       !org_id ||
//       !client_id ||
//       !timestamp ||
//       !signature
//     ) {
//       navigate("/invalidtoken");
//     } else {
//       validateEmbeddedLogin();
//     }
//   }, []);



  return (
    <>
      <>
      <header className="plcHeader">
        <div>
          <img
            src="img/logo_white.svg"
            height="40px"
            alt="logo"
            loading="lazy"
          />
        </div>
      </header>
        <Box className='content-center'>
      <Box sx={{ p: 5, display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
        <BoxWrapper>
          <Typography variant='h1'>401</Typography>
          <Typography variant='h5' sx={{ mb: 1, fontSize: '1.5rem !important' }}>
            You are not authorized! 🔐
          </Typography>
          <Typography variant='body2'>You don&prime;t have permission to access this page.</Typography>
        </BoxWrapper>
        {/* <img height='487' alt='error-illustration' src="img/401.png" /> */}
      </Box>
    </Box>
     
      </>
    </>
  );
};
export default InvalidLinkPage;
