import { Dialog, DialogContent, Typography, Box, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";

export default function ConfirmModal({
  handleThankyouModalClose,
  confirmModalOpen,
  onConfirm,
}) {
  return (
    <Dialog
      open={confirmModalOpen}
      fullWidth
      maxWidth="md"
      scroll="paper"
      PaperProps={{
        className: {
          maxHeight: "50vh",
        },
      }}
    >
      <DialogContent
        dividers={true}
        sx={{ padding: "50px", textAlign: "center" }}
      >
        <CloseIcon
          sx={{
            color: "#525252",
            position: "absolute",
            right: "30px",
            top: "30px",
            cursor: "pointer",
          }}
          onClick={handleThankyouModalClose}
        ></CloseIcon>
        <Typography
          textAlign={"center"}
          variant="h5"
          color={"#000"}
          sx={{ marginBottom: "15px" }}
        >
          Confirm details
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          pt={4}
          sx={{ borderTop: "1px dashed #ccc" }}
        >
          <h6>
            On Confirmation, We will generate DocuSign pdf to sign this might
            take a while please be patient.
          </h6>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="end">
          <div id="bottom-wizard">
            <input
              name="confirm"
              className="btn_1"
              value="Confirm"
              sx={{
                background: "#000054 !important",
                "&:hover": {
                  background: "#3d159d !important",
                },
              }}
              onClick={onConfirm}
            />
          </div>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
