import React from "react";

const Preloader = () => {
  return (
    <div>
      <div id="preloader">
        <div data-loader="circle-side"></div>
      </div>
      {/* Preload */}
    </div>
  );
};

export default Preloader;
