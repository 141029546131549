import moment from 'moment';

class DateHelper {

    formatedDate(date) {
        return new Date(date).toLocaleDateString('en-in', {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
        })
    }

    getTodayDate = () => moment();
    getYesterdayDate = () => moment().toDate();

}

export default new DateHelper();
