import { SvgIcon } from "@mui/material";
import React from "react";

const AlertSvg = () => {
  return (
    <SvgIcon
      sx={{ scale: "4", mb: "2rem" }}
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="80" height="80" rx="40" fill="#FFF8F8" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M40.5 26C41.8807 26 43 27.1193 43 28.5V42C43 43.3807 41.8807 44.5 40.5 44.5C39.1193 44.5 38 43.3807 38 42V28.5C38 27.1193 39.1193 26 40.5 26ZM31.615 59.8058C34.2308 60.9353 37.0258 61.5 40 61.5C42.9742 61.5 45.7692 60.9353 48.385 59.8058C51.0008 58.6778 53.2763 57.1463 55.2113 55.2113C57.1463 53.2763 58.6778 51.0008 59.8058 48.385C60.9353 45.7692 61.5 42.9742 61.5 40C61.5 37.0258 60.9353 34.2308 59.8058 31.615C58.6778 28.9992 57.1463 26.7238 55.2113 24.7888C53.2763 22.8538 51.0008 21.3215 48.385 20.192C45.7692 19.064 42.9742 18.5 40 18.5C37.0258 18.5 34.2308 19.064 31.615 20.192C28.9992 21.3215 26.7238 22.8538 24.7888 24.7888C22.8538 26.7238 21.3222 28.9992 20.1942 31.615C19.0647 34.2308 18.5 37.0258 18.5 40C18.5 42.9742 19.0647 45.7692 20.1942 48.385C21.3222 51.0008 22.8538 53.2763 24.7888 55.2113C26.7238 57.1463 28.9992 58.6778 31.615 59.8058ZM43 50.5C43 49.1193 41.8807 48 40.5 48C39.1193 48 38 49.1193 38 50.5C38 51.8807 39.1193 53 40.5 53C41.8807 53 43 51.8807 43 50.5Z"
        fill="#FE7777"
      />
    </SvgIcon>
  );
};

export default AlertSvg;
