import * as Yup from 'yup';
import datehelper from '../common/helper/datehelper';
import _ from "lodash";

const regex = {
  alphabetOnly: /^(?:(?=^.*([a-z]|[A-Z]))(([a-z]|[A-Z]))|([a-z]|[A-Z]))/g,
  alphaNumeric: /^[A-Za-z0-9]+$/,
  alphabetAndNumber: /^(?:(?=^.*([0-9]|[a-z]|[A-Z]))(([0-9]|[a-z]|[A-Z]))|([0-9]|[a-z]|[A-Z]))/g,
  alphaNumericHyphenAndForwardSlash: /^[A-Za-z0-9\/-]+$/,
  alphaNumericHyphenAndPlus: /^[A-Za-z0-9+-]+$/
}

function hasDuplicates(a) {
  return _.uniq(a).length !== a.length; 
}

// Yup validation for application form
export const validationSchema = {
  // Location
  locationSelect: Yup.object().shape({
    country: Yup.string().required('Location is required.'),
    countryCode: Yup.string().required('Location is required.')
  }),

  //Loan Info
  loanAmount: Yup.number()
    .required('Loan amount is required.')
    .typeError('Loan amount is required.')
    .moreThan('10', 'Loan amount should be more than 10 USD.'),
  loanTenure: Yup.number()
    .required('Loan tenure is required.')
    .typeError('Loan tenure is required.')
    .moreThan('1', 'Loan tenure should be a between 2 and 60.')
    .lessThan('61', 'Loan tenure should be a between 2 and 60.'),
  loanPurpose: Yup.string()
    .required('Loan purpose is required.')
    .typeError('Loan purpose is required.'),

  // Business
  businessRegCert: Yup.mixed().test(
    "required",
    "Please upload Business Registration Certificate.",
    file => {
      if (file && file.length) {
        return true
      }
      else {
        return false
      };
    }
  ).test(
    "required",
    "Business registration certificate size should be less than 10MB.",
    file => {
      if (file && file.length && ((file[0].size) / 1048576 < 10)) {
        return true
      }
      else {
        return false
      };
    }
  ).test(
    "required",
    "You can upload maximum 5 certificates.",
    file => {
      if (file && file.length > 5) {
        return false
      }
      else {
        return true
      };
    }
  ).test(
    'required.',
    'File with this name already exists.',
    file => {
      if (file && file.length) {
        const isValid = hasDuplicates(_.map(file, 'name'));
        return !isValid;
      }
      else{
        return true;
      }
    }
  ).test(
    'required.',
    'Please upload pdf, png, jpeg or jpg files only',
    file => {
      if (file && file.length) {
        var inValidFiles = []
        for (var f = 0; f < file.length; f++) {
          if (_.includes(['pdf', 'png', 'jpeg', 'jpg'], _.toLower(_.last(_.split(file[f]?.name, '.')))) === false) {
            inValidFiles.push(file[f])
          }
        }
        if (inValidFiles.length > 0) {
          return false;
        }
        else {
          return true;
        }
      }
    }
  ),
  businessRegNum: Yup.string()
    .required('Business registration number is required.')
    .matches(regex.alphaNumericHyphenAndForwardSlash, 'Please enter valid business registration number.'),

  businessName: Yup.string()
    .required('Business name is required.')
    .matches(regex.alphabetAndNumber, 'Please enter valid business name.')
    .min(2, 'Minimum 2 characters is required.'),

  businessDate: Yup.date()
    .required('Registration date is required.')
    .typeError('Please enter valid registration date.')
    .max(datehelper.getYesterdayDate(), 'Registration date should not be a future date.'),

  businessCountry: Yup.object().shape({
    country: Yup.string().required('Country of incorporation is required.')
  }),

  businessAddrCountry: Yup.object().shape({
    country: Yup.string().required('Country is required.'),
    countryCode: Yup.string().required('Country is required.')
  }),

  businessState: Yup.string().required('State/Province is required.'),

  industry: Yup.string().required('Industry or Sector is required.'),

  businessAddr: Yup.string().required('Registered business address is required.')
    .matches(regex.alphabetAndNumber, 'Please enter valid business address.')
    .max(500, 'Business address must be less than 500 characters.'),

  businessZipCode: Yup.string().required('Zipcode is required.')
    .matches(regex.alphaNumeric, 'Please enter valid zipcode.'),

  businessEmail: Yup.string()
    .email('Please enter valid business email address.')
    .required('Business email address is required.'),
  businessPhone: Yup.string()
    .required('Business mobile number is required.'),

  // Applicant
  applicantName: Yup.string()
    .required('Applicant name is required.')
    .matches(regex.alphabetOnly, 'Please enter valid applicant name.'),
  applicantNationality: Yup.object().shape({
    country: Yup.string().required('Applicant nationality is required.')
  }),
  residentialStatus: Yup.string()
    .required('Residential status is required.')
    .matches(regex.alphabetOnly, 'Please enter valid residential status.'),
  residentialAddr: Yup.string()
    .required('Residential address is required.')
    .matches(regex.alphabetAndNumber, 'Please enter valid residential address.')
    .max(500, 'Residential address must be less than 500 characters.'),
  applicantZip: Yup.string().required('Zipcode is required.')
    .matches(regex.alphaNumeric, 'Please enter valid ipcode.'),

  applicantEmail: Yup.string()
    .email('Please enter valid applicant email address.')
    .required('Applicant email is required.'),

  applicantPhone: Yup.string()
    .required('Applicant mobile number is required.'),

  // Documents
  bankStatements: Yup.mixed().test(
    'required.',
    'Please upload bank statements',
    file => {
      if (file && file.length) return true;
      else return false;
    }
  ).test(
    'required.',
    'Please upload pdf bank statements',
    file => {
      if (file && file.length) {
        var inValidFiles = []
        for (var f = 0; f < file.length; f++) {
          if (_.includes(['pdf'], _.toLower(_.last(_.split(file[f]?.name, '.')))) === false) {
            inValidFiles.push(file[f])
          }
        }
        if (inValidFiles.length > 0) {
          return false;
        }
        else {
          return true;
        }
      }
    }
  ).test(
    "required",
    "You can upload maximum 30 bank statements.",
    file => {
      if (file && file.length > 30) {
        return false
      }
      else {
        return true
      };
    }
  ).test(
    'required.',
    'File with this name already exists.',
    file => {
      if (file && file.length) {
        const isValid = hasDuplicates(_.map(file, 'name'));
        return !isValid;
      }
      else{
        return true;
      }
    }
  ),
  financeStatements: Yup.mixed().test(
    'required.',
    'Please upload pdf, png, jpeg or jpg files only.',
    file => {
      if (file && file.length) {
        var inValidFiles = []
        for (var f = 0; f < file.length; f++) {
          if (_.includes(['pdf', 'png', 'jpeg', 'jpg'], _.toLower(_.last(_.split(file[f]?.name, '.')))) === false) {
            inValidFiles.push(file[f])
          }
        }
        if (inValidFiles.length > 0) {
          return false;
        }
        else {
          return true;
        }
      }
      else {
        return true;
      }
    }
  ).test(
    'required.',
    'File with this name already exists.',
    file => {
      if (file && file.length) {
        const isValid = hasDuplicates(_.map(file, 'name'));
        return !isValid;
      }
      else{
        return true;
      }
    }
  ).test(
    "required",
    "You can upload maximum 5 finance statements.",
    file => {
      if (file && file.length > 5) {
        return false
      }
      else {
        return true
      };
    }
  ).when('loanAmount', (loanAmount) => {
    if (loanAmount > 200000) {
      return Yup.mixed().test(
        'required.',
        'Please upload financial statements',
        file => {
          if (file && file.length) return true;
          else return false;
        }
      ).test(
        'required.',
        'Please upload pdf, png, jpeg or jpg files only.',
        file => {
          if (file && file.length) {
            var inValidFiles = []
            for (var f = 0; f < file.length; f++) {
              if (_.includes(['pdf', 'png', 'jpeg', 'jpg'], _.toLower(_.last(_.split(file[f]?.name, '.')))) === false) {
                inValidFiles.push(file[f])
              }
            }
            if (inValidFiles.length > 0) {
              return false;
            }
            else {
              return true;
            }
          }
          else {
            return true;
          }
        }
      ).test(
        'required.',
        'File with this name already exists.',
        file => {
          if (file && file.length) {
            const isValid = hasDuplicates(_.map(file, 'name'));
            return !isValid;
          }
          else{
            return true;
          }
        }
      ).test(
        "required",
        "You can upload maximum 5 finance statements.",
        file => {
          if (file && file.length > 5) {
            return false
          }
          else {
            return true
          };
        }
      )
    }
  }),

  gstStatements: Yup.mixed().test(
    'required.',
    'Please upload pdf file only.',
    file => {
      if (file && file.length) {
        var inValidFiles = []
        for (var f = 0; f < file.length; f++) {
          if (_.includes(['pdf'], _.toLower(_.last(_.split(file[f]?.name, '.')))) === false) {
            inValidFiles.push(file[f])
          }
        }
        if (inValidFiles.length > 0) {
          return false;
        }
        else {
          return true;
        }
      }
      else {
        return true;
      }
    }
  ).test(
    "required",
    "You can upload maximum 5 gst statements.",
    file => {
      if (file && file.length > 5) {
        return false
      }
      else {
        return true
      };
    }
  ).test(
    'required.',
    'File with this name already exists.',
    file => {
      if (file && file.length) {
        const isValid = hasDuplicates(_.map(file, 'name'));
        return !isValid;
      }
      else{
        return true;
      }
    }
  ),

  // Directors1 Information
  directorName1: Yup.string()
    .required('Director name is required.')
    .matches(regex.alphabetOnly, 'Please enter valid director name.'),

  personalIdentificationNumber1: Yup.string()
    .required('Personal identification number is required.')
    .typeError('Personal identification number is required.')
    .matches(regex.alphaNumericHyphenAndPlus, 'Please enter valid personal identification number.'),

  shareholding1: Yup.number()
    .required('Shareholding is required.')
    .typeError('Shareholding is required.')
    .moreThan('0', 'Share holding should be a between 1 and 100.')
    .lessThan('101', 'Share holding should be a between 1 and 100.'),

  directorAddress1: Yup.string()
    .required('Residential address is required.')
    .matches(regex.alphabetAndNumber, 'Please enter valid residential address.')
    .max(500, 'Residential address must be less than 500 characters.'),

  directorZipCode1: Yup.string().required('Zipcode is required.')
    .matches(regex.alphaNumeric, 'Please enter valid zipcode.'),

  directorCountry1: Yup.object().shape({
    country: Yup.string().required('Country is required.'),
    countryCode: Yup.string().required('Country is required.')
  }),

  directorPhone1: Yup.string()
    .required('Phone number is required.'),

  directorEmail1: Yup.string()
    .email('Please enter valid email address.')
    .required('Email address is required.'),

  videoCallDate1: Yup.date()
    .required('Video call date is required.')
    .typeError('Please enter valid registration video call date.')
    .min(datehelper.getTodayDate(), "Video call date should be future date."),

  videoCallTime1: Yup.date()
    .required('Video call time is required.')
    .typeError('Please enter valid registration video call time.'),

  passport1: Yup.mixed().test(
    'required.',
    'Please upload passport images/pdf.',
    file => {
      if (file && file.length) return true;
      else return false;
    }
  ).test(
    'required.',
    'Please upload pdf, png, jpeg or jpg files only',
    file => {
      if (file && file.length) {
        var inValidFiles = []
        for (var f = 0; f < file.length; f++) {
          if (_.includes(['pdf', 'png', 'jpeg', 'jpg'], _.toLower(_.last(_.split(file[f]?.name, '.')))) === false) {
            inValidFiles.push(file[f])
          }
        }
        if (inValidFiles.length > 0) {
          return false;
        }
        else {
          return true;
        }
      }
    }
  ).test(
    "required",
    "You can upload maximum 5 files.",
    file => {
      if (file && file.length > 5) {
        return false
      }
      else {
        return true
      };
    }
  ).test(
    'required.',
    'File with this name already exists.',
    file => {
      if (file && file.length) {
        const isValid = hasDuplicates(_.map(file, 'name'));
        return !isValid;
      }
      else{
        return true;
      }
    }
  ),
  utilityBills1: Yup.mixed().test(
    'required.',
    'Please upload utility bills',
    file => {
      if (file && file.length) return true;
      else return false;
    }
  ).test(
    'required.',
    'Please upload pdf, png, jpeg or jpg files only',
    file => {
      if (file && file.length) {
        var inValidFiles = []
        for (var f = 0; f < file.length; f++) {
          if (_.includes(['pdf', 'png', 'jpeg', 'jpg'], _.toLower(_.last(_.split(file[f]?.name, '.')))) === false) {
            inValidFiles.push(file[f])
          }
        }
        if (inValidFiles.length > 0) {
          return false;
        }
        else {
          return true;
        }
      }
    }
  ).test(
    "required",
    "You can upload maximum 5 utility bills.",
    file => {
      if (file && file.length > 5) {
        return false
      }
      else {
        return true
      };
    }
  ).test(
    'required.',
    'File with this name already exists.',
    file => {
      if (file && file.length) {
        const isValid = hasDuplicates(_.map(file, 'name'));
        return !isValid;
      }
      else{
        return true;
      }
    }
  ),

  // Directors2 Information

  // T&C
  checkTerms: Yup.bool().oneOf([true], 'Message'),
  checkDataProtection: Yup.bool().oneOf([true], 'Message')
}
