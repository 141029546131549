import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import React from 'react';
import { lowerCase } from 'lodash';

export default function RRPhoneInput({ inputProps, ...rest }) {
    const { country, inputClass, value, placeholder, onChange } = rest;

    const onFieldChange = (value, country, e, formattedValue) => {
        onChange({ value, country, e, formattedValue });
    }

    return (

        <PhoneInput
            value={value}
            placeholder={placeholder}
            country={country ? lowerCase(country) : ''}
            onChange={onFieldChange}
            inputClass={inputClass}
            {...inputProps}
        />

    )
}
