import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import LoanApp from "./pages/loan-app/LoanApp.component";
import Home from "./pages/Home";
import Preloader from "./common/preloader/preloader.component";
import ThankYou from "./pages/ThankYou";
import InvalidLinkPage from "./pages/invalid-link/InvalidLink.component";
import DocuSign from "./pages/docusign/DocuSign";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n/config";
import { ThemeProvider } from "@emotion/react";
import theme from "./common/theme/theme";

const App = () => {
  return (
    <I18nextProvider i18n={i18n}>
      <ThemeProvider theme={theme}>
        <div className="App">
          <Suspense fallback={<Preloader />}>
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/PLD" element={<LoanApp />} />
              <Route
                exact
                path="/ThankYou/:applicantName/:phone/:id"
                element={<ThankYou />}
              />
              <Route exact path="/RRF" element={<LoanApp />} />
              <Route exact path="/invalidlink" element={<InvalidLinkPage />} />
              <Route exact path="/docu-sign" element={<DocuSign />} />
            </Routes>
          </Suspense>
        </div>
      </ThemeProvider>
    </I18nextProvider>
  );
};

export default App;
