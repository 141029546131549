import axios from "axios";
import { isString } from "lodash";

// Defaults
axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URI;

// Request interceptor
axios.interceptors.request.use(
  async (config) => {
    config.headers = {
      ...config.headers,
    };

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
axios.interceptors.response.use(
  async (response) => {
    // const { data, errors } = response.data
    // if (data) {
    //   return data
    // }

    // console.log(errors) // Error can be handled by showing toast messages or validation messages on form.
    return response?.data;
  },
  async (error) => {
    // Error can be handled by showing toast messages or validation messages on screen.
    if (isString(error?.response?.data?.message)) {
      console.log({ message: error?.response?.data?.message });
    }

    // In case of api error we can pass null to the api caller, but if we want to pase some validation message to caller component then we can return { data: null, error }
    return { data: null, error };
  }
);

function generateConfig(method, url, data, params, headers, responseType) {
  const config = { method, url };
  if (headers) {
    config["headers"] = headers;
  }

  if (data) {
    config["data"] = data;
  }

  if (responseType) {
    config["responseType"] = "blob";
  }

  if (params) {
    config["params"] = params;
    if (config.url.includes("{")) {
      config.url = config.url.replace(/{(.*?)}/g, (match) => {
        return params[match.slice(1, -1)];
      });
    }
  }

  return config;
}

async function serviceCall(method, url, data, params, headers, responseType) {
  const config = generateConfig(
    method,
    url,
    data,
    params,
    headers,
    responseType
  );
  const result = await axios(config);

  return result;
}

// -------------------------------------------------------------- API Service Methods
//#region
async function get(
  url,
  data = null,
  params = null,
  extraHeaders = {},
  responseType = null
) {
  const headers = {
    "Content-Type": "application/json",
    ...extraHeaders,
  };

  return serviceCall("get", url, data, params, headers, responseType);
}

async function post(
  url,
  data = null,
  params = null,
  extraHeaders,
  responseType
) {
  const headers = {
    "Content-Type": "application/json",
    "Cache-Control": "no-cache",
    ...extraHeaders,
  };

  return serviceCall("post", url, data, params, headers, responseType);
}

async function put(url, data = null, params = null) {
  const headers = {
    "Content-Type": "application/json",
  };
  console.log("params", params);

  return serviceCall("put", url, data, params, headers);
}

async function deleteCall(url, data = null, params = null) {
  const headers = {
    "Content-Type": "application/json",
  };

  return serviceCall("delete", url, data, params, headers);
}

const ApiService = {
  get,
  post,
  put,
  deleteCall,
};

//#endregion

export default ApiService;

// -------------------------------------------------------------- API URLs
//#region
export const ApiUrls = {
  // Loan Application APIs
  LoanApplication: "loan-application/create",
  KycApplicationStep1: "kyc-application/kyc/business",
  KycApplicationStep2: "kyc-application/kyc/director/{requestId}",
  GetRequest: "kyc-application/kyc/{customerRefNo}",
  GetDocusignUrl: "kyc-application/kyc-docusign/{customerRefNo}",
  GetLoo: "kyc-application/kyc-loo/{customerRefNo}",

  // Embedded Login
  EmbeddedLogin: "authentication/client/embedded-login",

  GetUrlToSign: "kyc-application/get-url",
  UpdateDirectorSign: "kyc-application/director-sign",
  GetAddresses: "kyc-application/addresses",

  GetCountryDocuments: "kyc-application/kyc-document-types",
  DocusignVerifyHMAC: "kyc-application/docusign-verify",
};
//#endregion
