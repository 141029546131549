import i18next from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      step1_head: "Customer Company Information",
      step1_desc: "Fill the Customer Company Information",
      step2_head: "Director Information",
      step2_desc: "Fill the Director Information",
      business_registration_number: "Company Registration Number *",
      business_name: "Company Name *",
      business_registration_date: "Company Registration Date *",
      incorp_country: "Country where company is registered *",
      business_industry: "Industry *",
      select_business_industry: "Select Industry *",
      business_address: "Company Address *",
      business_zipCode: "ZipCode *",
      business_district: "District *",
      business_city: "City *",
      business_area: "Area *",
      business_country: "Select Company Country *",
      director_country: "Select Country *",
      business_state: "State *",
      business_email: "Company Email *",
      business_phone: "Phone Number *",
      customer_reference_no: "Customer Reference No *",
      businessEmail: "Company Email *",
      business_desc: "Company Description *",
      tax_id_number: "Tax Identification Number *",
      e_b_tax_id_number_invalid: "Tax number must be 15 digits long",
      e_d_directorCity2_required: "City is required",
      e_d_directorCity1_required: "City is required",
      e_d_directorState1_required: "State is required",
      e_d_directorState2_required: "State is required",
      e_d_directorDistrict1_required: "District is required",
      e_d_directorDistrict2_required: "District is required",
      e_d_personal_id_number_invalid:
        "Personal Identification number must be 16 digits long",
      bank_name: "Bank Name *",
      bank_account_number: "Bank Account Number *",
      bank_account_name: "Bank Account Name *",
      bank_swift_code: "Bank Swift Code *",
      bank_address: "Bank Address *",
      business_registration_cert: "Upload Company Registration Certificate. *",
      business_document_types: "Business Document Types",
      business_document_types_label: "Business Document Types",
      e_b_name_required: "Company name is required.",
      e_b_invalid_name: "Please enter valid company name.",
      e_b_min_length: "Minimum 2 characters is required.",
      e_b_area_required: "Area is required",
      e_b_invalid_email: "Please enter valid company email address.",
      e_b_email_required: "Company email address is required.",
      e_b_reg_no_required: "Company registration number is required.",
      e_b_invalid_reg_no: "Please enter valid company registration number.",
      e_b_reg_date_required: "Registration date is required.",
      e_b_invalid_reg_date: "Please enter valid registration date.",
      e_b_future_reg_date: "Registration date should not be a future date.",
      e_b_country_required: "Country of incorporation is required.",
      e_b_address_country_required: "Country is required.",
      e_b_district_required: "District is required.",
      e_b_state_required: "State/Province is required.",
      e_b_city_required: "City is required.",
      e_b_industry_required: "Industry or Sector is required.",
      e_b_address_required: "Registered company address is required.",
      e_b_invalid_address: "Please enter valid company address.",
      e_b_max_address: "Company address must be less than 500 characters.",
      e_b_zipcode_required: "Zipcode is required.",
      e_b_invalid_zipcode: "Please enter valid zipcode.",
      e_b_phone_required: "Company mobile number is required.",
      e_b_desc_required: "Company description is required.",
      e_b_tax_id_number_required: "Tax identification number is required.",
      e_b_bank_name_required: "Bank name is required.",
      e_b_bank_account_number_required: "Bank account number is required.",
      e_b_registration_cert_required:
        "Please upload Company Registration Certificate.",
      e_b_registration_cert_size:
        "Company registration certificate size should be less than 10MB.",
      e_b_max_registration_cert: "You can upload maximum 5 certificates.",
      e_b_registration_cert_already_exist:
        "File with this name already exists.",
      e_b_registration_cert_file_type:
        "Please upload pdf, png, jpeg or jpg files only",
      e_b_customer_ref_no: "Customer reference number is required.",
      e_b_invalid_customer_ref_no:
        "Please enter valid customer reference number.",
      e_b_bank_account_name_required: "Bank account name is required.",
      e_b_invalid_bank_account_name: "Please enter valid bank account name.",
      e_b_bank_swift_code_length:
        "Please enter valid swift code having 8 OR 11 characters.",
      e_b_bank_swift_code_required: "Bank swift code is required.",
      e_b_invalid_bank_swift_code: "Please enter valid bank swift code.",
      e_b_bank_address_required: "Bank address is required.",
      e_b_invalid_bank_address: "Please enter valid bank address.",
      step2_sub_head_1: "Director1 Information", //
      step2_sub_head_2: "Director2 Information",
      director_name: "Director Name *",
      director_personal_id_number: "Personal Identification Number *",
      share_holding: "Share Holding *",
      director_address: "Director Address *",
      director_zip: "Director ZipCode *",
      director_state: "Director State *",
      director_district: "Director District *",
      director_city: "Director City *",
      director_area: "Director Area *",
      director_phone: "Phone Number *",
      director_email: "Director Email *",
      director_files_head:
        "Upload Director Files (image clicked by phone or scanned) *",
      director_files_desc:
        "The uploaded file (PDF or JPEG or PNG) should clearly show relevant information",
      passport_head: "Upload Passport (image clicked by phone or scanned) *",
      passpord_desc:
        "The uploaded file (PDF or JPEG or PNG)should clearly show (photograph, name, passport number, and date of birth)",
      utilitybills_head:
        "Upload Utility Bill (water, electricity, gas, credit card, phone/mobile, rent agreement) *",
      utilitybills_desc:
        "The uploaded file (PDF or JPEG or PNG) should clearly show the address and name. The bill cannot be older than 3 months from the current date",
      not_applicable: "Not Applicable",
      info_not_applicable:
        "You can mark this as not applicable if your company has only 1 director",
      director_document_types: "Director Document Types.",
      director_document_types_label: "Director Document Types.",
      e_d_name_required: "Director name is required.",
      e_d_invalid_name: "Please enter valid director name.",
      e_d_personal_id_number_required:
        "Personal identification number is required.",
      e_d_same_personal_id:
        "This Personal Identification Number is already entered for other Director.",
      e_d_invalid_personal_id_number:
        "Please enter valid personal identification number.",
      e_d_shareholding_required: "Shareholding is required.",
      e_d_shareholding_range: "Share holding should be a between 1 and 100.",
      e_d_address_required: "Residential address is required.",
      e_d_invalid_address: "Please enter valid residential address.",
      e_d_max_address: "Residential address must be less than 500 characters.",
      e_d_zip_required: "Zipcode is required.",
      e_d_invalid_zip: "Please enter valid zipcode.",
      e_d_country_required: "Country is required.",
      e_d_phone_required: "Phone number is required.",
      e_d_same_phone:
        "This Phone number is already entered for other Director.",
      e_d_invalid_email: "Please enter valid email address.",
      e_d_email_required: "Email address is required.",
      e_d_same_email:
        "This email address is already enterd for other Director.",
      e_d_files_required: "Please upload director files (images/pdf).",
      e_d_files_type: "Please upload pdf, png, jpeg or jpg files only",
      e_d_files_length: "You can upload maximum 5 files.",
      e_d_duplicate_files: "File with this name already exists.",
      e_d_utilitybills_required: "Please upload utility bills",
      e_d_utilitybills_type: "Please upload pdf, png, jpeg or jpg files only",
      e_d_utilitybills_length: "You can upload maximum 5 utility bills.",
      e_d_duplicate_utilitybills: "File with this name already exists.",
      btn_next: "Next",
      btn_submit: "Submit For Review",
      btn_back: "Back",
      thankyou_head:
        "Thank you!! Your application has been successfully submitted. We are verifying your KYC data. We willget back to you shortly with Final approved credit line based on your KYC data.",
      thankyou_customer_reg_number: "Company Registration Number :",
      thankyou_company_name: "Company Name :",
      thankyou_mobile_name: "Mobile Number :",
      thankyou_support:
        " You can reach us by emailing at loans@credilinq.ai and use above information as reference",
      "Service Sector – F&B": "Service Sector – F&B",
      "Service Sector – non F&B": "Service Sector – non F&B",
      "Health & Human Services": "Health & Human Services",
      Education: "Education",
      "IT services": "IT services",
      Media: "Media",
      "Agriculture / Fishing / Food Production":
        "Agriculture / Fishing / Food Production",
      "Wholesale Trader": "Wholesale Trader",
      "Retail Trading": "Retail Trading",
      "Manufacturing – heavy industries": "Manufacturing – heavy industries",
      "Manufacturing – consumer related": "Manufacturing – consumer related",
      "Manufacturing – computer & electronics":
        "Manufacturing – computer & electronics",
      "Energy – generation and distribution":
        "Energy – generation and distribution",
      "Transport & Logistics": "Transport & Logistics",
      Renewable: "Renewable",
      "Building and Construction - Private Sector":
        "Building and Construction - Private Sector",
      "Building and Construction - Public Sector":
        "Building and Construction - Public Sector",
      Others: "Others",
      Required: "Required",
      Optional: "Optional",
      file_name: "File Name",
      Kyc_Doc_Type: "KYC DOC Type",
      Size: "size",
      file_type: "File Type",
      Type: "Type",
      Actions: "Actions",
      delete_document: "Delete Document",
    },
  },
  id: {
    translation: {
      step1_head: "Informasi Perusahaan Pelanggan",
      step1_desc: "Isi Informasi Perusahaan Pelanggan",
      step2_head: "Informasi Direktur",
      step2_desc: "Isi Informasi Direktur",
      business_registration_number: "Nomor registrasi perusahaan *",
      business_name: "Nama perusahaan *",
      business_registration_date: "Tanggal Pendaftaran Perusahaan *",
      incorp_country: "Negara tempat perusahaan terdaftar *",
      business_industry: "Industri *",
      business_address: "Alamat perusahaan *",
      business_zipCode: "Kode Pos *",
      business_country: "Pilih Negara Perusahaan *",
      director_country: "Pilih negara *",
      business_state: "Negara *",
      business_district: "Daerah",
      business_city: "Kota",
      business_area: "Sub-Daerah",
      business_email: "Email Perusahaan *",
      business_phone: "Masukkan Nomor Ponsel *",
      customer_reference_no: "Nomor Referensi Pelanggan *",
      business_desc: "Deskripsi Perusahaan *",
      tax_id_number: "Nomor Pokok Wajib Pajak *",
      bank_name: "Nama Bank *",
      bank_account_number: "Nomor rekening bank *",
      bank_account_name: "Nama akun bank *",
      bank_swift_code: "Kode Bank Swift *",
      bank_address: "Alamat bank *",
      business_registration_cert: "Unggah Tanda Daftar Perusahaan. ",
      e_b_area_required: "Area diperlukan",
      e_b_tax_id_number_invalid: "Panjang nomor pajak harus 15 digit",
      e_d_directorCity2_required: "Kota diperlukan",
      e_d_directorCity1_required: "Kota diperlukan",
      e_d_directorState1_required: "Negara diperlukan",
      e_d_directorState2_required: "Negara diperlukan",
      e_d_directorDistrict1_required: "Distrik diperlukan",
      e_d_directorDistrict2_required: "Distrik diperlukan",
      e_b_district_required: "Distrik diperlukan",
      e_b_city_required: "Kota diperlukan.",
      btn_back: "Kembali",
      e_b_name_required: "Nama perusahaan wajib diisi.",
      e_b_invalid_name: "Masukkan nama perusahaan yang valid.",
      e_b_min_length: "Diperlukan minimal 2 karakter.",
      e_b_invalid_email: "Masukkan alamat email perusahaan yang valid.",
      e_b_email_required: "Alamat email perusahaan diperlukan.",
      e_b_reg_no_required: "Nomor pendaftaran perusahaan diperlukan.",
      e_b_invalid_reg_no: "Masukkan nomor pendaftaran perusahaan yang valid.",
      e_b_reg_date_required: "Tanggal pendaftaran diperlukan.",
      e_b_invalid_reg_date: "Masukkan tanggal pendaftaran yang valid.",
      e_b_future_reg_date:
        "Tanggal pendaftaran tidak boleh tanggal di masa mendatang.",
      e_b_country_required: "Negara pendirian wajib diisi.",
      e_b_address_country_required: "Negara diperlukan.",
      e_b_state_required: "Negara Bagian/Provinsi wajib diisi.",
      e_b_industry_required: "Industri atau Sektor diperlukan.",
      e_b_address_required: "Alamat perusahaan terdaftar diperlukan.",
      e_b_invalid_address: "Masukkan alamat perusahaan yang valid.",
      e_b_max_address: "Alamat perusahaan harus kurang dari 500 karakter.",
      e_b_zipcode_required: "Kode pos diperlukan.",
      e_b_invalid_zipcode: "Masukkan kode pos yang valid.",
      e_b_phone_required: "Nomor ponsel perusahaan diperlukan.",
      e_b_desc_required: "Deskripsi perusahaan diperlukan.",
      e_b_tax_id_number_required: "Nomor pokok wajib pajak diperlukan.",
      e_b_bank_name_required: "Nama bank diperlukan.",
      e_b_bank_account_number_required: "Nomor rekening bank diperlukan.",
      e_b_registration_cert_required: "Unggah Tanda Daftar Perusahaan.",
      e_b_registration_cert_size:
        "Ukuran sertifikat pendaftaran perusahaan harus kurang dari 10 MB.",
      e_b_max_registration_cert: "Anda dapat mengunggah maksimal 5 sertifikat.",
      e_b_registration_cert_already_exist: "File dengan nama ini sudah ada.",
      e_b_registration_cert_file_type:
        "Harap unggah file pdf, png, jpeg atau jpg saja",
      e_b_customer_ref_no: "Nomor referensi pelanggan diperlukan.",
      e_b_invalid_customer_ref_no:
        "Masukkan nomor referensi pelanggan yang valid.",
      e_b_bank_account_name_required: "Nama rekening bank diperlukan.",
      e_b_invalid_bank_account_name: "Masukkan nama rekening bank yang valid.",
      e_b_bank_swift_code_length:
        "Masukkan kode cepat yang valid dengan 8 ATAU 11 karakter.",
      e_b_bank_swift_code_required: "Kode swift bank diperlukan.",
      e_b_invalid_bank_swift_code: "Masukkan kode swift bank yang valid.",
      e_b_bank_address_required: "Alamat bank diperlukan.",
      e_b_invalid_bank_address: "Masukkan alamat bank yang valid.",
      step2_sub_head_1: "Direktur1 Informasi",
      step2_sub_head_2: "Direktur2 Informasi",
      director_name: "Nama Direktur *",
      director_personal_id_number: "Nomor Identifikasi Pribadi *",
      share_holding: "Memegang Saham *",
      director_address: "Alamat Direktur *",
      director_zip: "Kode Pos Direktur *",
      director_state: "Propinsi Direktur *",
      director_district: "Daerah Direktur",
      director_city: "Kota Direktur",
      director_area: "Sub-Daerah Direktur",
      director_country: "Pilih negara *",
      director_phone: "Masukkan nomor telepon *",
      director_email: "Email Direktur *",
      e_d_personal_id_number_invalid:
        "Nomor Identifikasi Pribadi harus sepanjang 16 digit",
      passport_head:
        "Unggah Paspor (gambar diklik melalui telepon atau dipindai) *",
      passpord_desc:
        "File yang diunggah (PDF atau JPEG atau PNG) harus jelas menunjukkan (foto, nama, nomor dan tanggal lahir)",
      utilitybills_head:
        "Unggah Bukti Identitas (Gambar Selfie, Tagihan Utilitas (air, listrik, gas), kartu kredit, telepon/ponsel, perjanjian sewa) *",
      utilitybills_desc:
        "File yang diunggah (PDF atau JPEG atau PNG) harus jelas & menunjukkan detailnya.",
      not_applicable: "Tak dapat diterapkan",
      info_not_applicable:
        "Anda dapat menandai ini sebagai tidak berlaku jika perusahaan Anda hanya memiliki 1 direktur",
      e_d_name_required: "Nama direktur wajib diisi.",
      e_d_invalid_name: "Masukkan nama direktur yang valid.",
      e_d_personal_id_number_required: "Nomor identifikasi pribadi diperlukan.",
      e_d_same_personal_id:
        "Nomor Identifikasi Pribadi ini sudah dimasukkan untuk Direktur lain.",
      e_d_invalid_personal_id_number:
        "Masukkan nomor identifikasi pribadi yang valid.",
      e_d_shareholding_required: "Kepemilikan saham diperlukan.",
      e_d_shareholding_range: "Kepemilikan saham harus antara 1 dan 100.",
      e_d_address_required: "Alamat tempat tinggal diperlukan.",
      e_d_invalid_address: "Masukkan alamat tempat tinggal yang valid.",
      e_d_max_address: "Alamat tempat tinggal harus kurang dari 500 karakter.",
      e_d_zip_required: "Kode pos diperlukan.",
      e_d_invalid_zip: "Masukkan kode pos yang valid.",
      e_d_country_required: "Negara diperlukan.",
      e_d_phone_required: "Nomor telepon diperlukan.",
      e_d_same_phone: "Nomor Telepon ini sudah dimasukkan untuk Direktur lain.",
      e_d_invalid_email: "Masukkan alamat email yang valid.",
      e_d_email_required: "Alamat email diperlukan.",
      e_d_same_email: "Alamat email ini sudah dimasukkan untuk direktur 1.",
      e_d_files_required: "Harap unggah file sutradara (gambar/pdf).",
      e_d_files_type: "Harap unggah file pdf, png, jpeg atau jpg saja",
      e_d_files_length: "Anda dapat mengunggah maksimal 5 file.",
      e_d_duplicate_files: "File dengan nama ini sudah ada.",
      e_d_utilitybills_required: "Unggah tagihan utilitas",
      e_d_utilitybills_type: "Harap unggah file pdf, png, jpeg atau jpg saja",
      e_d_utilitybills_length:
        "Anda dapat mengunggah maksimal 5 tagihan utilitas.",
      e_d_duplicate_utilitybills: "File dengan nama ini sudah ada.",
      btn_next: "Berikutnya",
      btn_submit: "Kirim Untuk Ditinjau",
      thankyou_head:
        "Terima kasih!! Aplikasi Anda telah berhasil dikirim. Kami sedang memverifikasi data KYC Anda. Kami akan segera menghubungi Anda kembali dengan kredit maksimum yang disetujui berdasarkan data KYC Anda.",
      thankyou_customer_reg_number: "Nomor registrasi perusahaan :",
      thankyou_company_name: "Nama perusahaan :",
      thankyou_mobile_name: "Nomor handphone :",
      thankyou_support:
        " Anda dapat menghubungi kami dengan mengirim email ke loan@credilinq.ai dan menggunakan informasi di atas sebagai referensi",
      "Service Sector – F&B": "Sektor Jasa – F",
      "Service Sector – non F&B": "Sektor Jasa – non F",
      "Health & Human Services": "Kesehatan",
      Education: "Pendidikan",
      "IT services": "layanan TI",
      Media: "Media",
      "Agriculture / Fishing / Food Production":
        "Pertanian / Perikanan / Produksi Pangan",
      "Wholesale Trader": "Pedagang Grosir",
      "Retail Trading": "Perdagangan Ritel",
      "Manufacturing – heavy industries": "Manufaktur – industri berat",
      "Manufacturing – consumer related": "Manufaktur – terkait konsumen",
      "Manufacturing – computer & electronics": "Manufaktur – komputer",
      "Energy – generation and distribution":
        "Energi – pembangkitan dan distribusi",
      "Transport & Logistics": "Mengangkut",
      Renewable: "Terbarukan",
      "Building and Construction - Private Sector":
        "Bangunan dan Konstruksi - Sektor Swasta",
      "Building and Construction - Public Sector":
        "Bangunan dan Konstruksi - Sektor Publik",
      Others: "Yang lain",
      director_document_types: "Jenis Dokumen Direktur.",
      director_document_types_label: "Jenis Dokumen Direktur.",
      director_files_head:
        "Unggah File Direktur (gambar diklik melalui telepon atau dipindai) *",
      director_files_desc:
        "File yang diunggah (PDF atau JPEG atau PNG) harus dengan jelas menunjukkan informasi yang relevan",
      business_document_types: "Jenis Dokumen Bisnis",
      business_document_types_label: "Jenis Dokumen Bisnis",
      Required: "Diperlukan",
      Optional: "Opsional",
      file_name: "Nama file",
      Kyc_Doc_Type: "Tipe DOC KYC",
      Size: "Ukuran",
      file_type: "Jenis File",
      Type: "Jenis",
      Actions: "Tindakan",
      delete_document: "Hapus Dokumen",
    },
  },
};
i18next.use(initReactI18next).init({
  resources,
  lng: "en",
  interpolation: {
    escapeValue: false,
  },
});
export default i18next;
