



export const SupportedMobileNumberPrexiesForIndonesia = [
  '0811', '811', '0812', '812', '0813', '813', '0821', '821', '0822', '822',
  '0823', '823', '0851', '851', '0852', '852', '0853', '853', '0814', '814',
  '0815', '815', '0816', '816', '0855', '855', '0856', '856', '0857', '857',
  '0858', '858', '0817', '817', '0818', '818', '0819', '819', '0859', '859',
  '0877', '877', '0878', '878', '0831', '0832', '0833', '0838', '831', '832',
  '833', '838', '0895', '895', '0896', '896', '0897', '897', '0898', '898',
  '0899', '899', '0881', '0882', '0883', '0884', '0885', '0886', '0887',
  '0888', '0889', '881', '882', '883', '884', '885', '886', '887', '888', '889'
];
export const TermsConditions =
  "https://alexi.credilinq.ai/files/Standard_Customer_T&Cs.pdf";
export const DataProtection =
  "https://alexi-react.credilinq.ai/files/notice.pdf";
export const loanPurposeData = [
  {
    label: "Business Expansion",
    value: "Business Expansion",
  },
  {
    label: "Equipment Purchase",
    value: "Equipment Purchase",
  },
  {
    label: "Infrastructure Upgrade",
    value: "Infrastructure Upgrade",
  },
  {
    label: "Pay Invoices",
    value: "Pay Invoices",
  },
  {
    label: "Working Capital",
    value: "Working Capital",
  },
  {
    label: "Other",
    value: "Other",
  },
];

export const industryData = [
  "Service Sector – F&B",
  "Service Sector – non F&B",
  "Health & Human Services",
  "Education",
  "IT services",
  "Media",
  "Agriculture / Fishing / Food Production",
  "Wholesale Trader",
  "Retail Trading",
  "Manufacturing – heavy industries",
  "Manufacturing – consumer related",
  "Manufacturing – computer & electronics",
  "Energy – generation and distribution",
  "Transport & Logistics",
  "Renewable",
  "Building and Construction - Private Sector",
  "Building and Construction - Public Sector",
  "Others",
];

export const currencyData = [
  {
    code: "AF",
    name: "Afghanistan",
    currency: "AFN",
    locale: "ps-AF",
  },
  {
    code: "AL",
    name: "Albania",
    currency: "ALL",
    locale: "sq-AL",
  },
  {
    code: "DZ",
    name: "Algeria",
    currency: "DZD",
    locale: "ar-DZ",
  },
  {
    code: "AS",
    name: "American Samoa",
    currency: "USD",
    locale: "en-AS",
  },
  {
    code: "AD",
    name: "Andorra",
    currency: "EUR",
    locale: "ca",
  },
  {
    code: "AO",
    name: "Angola",
    currency: "AOA",
    locale: "pt",
  },
  {
    code: "AI",
    name: "Anguilla",
    currency: "XCD",
    locale: "en",
  },
  {
    code: "AQ",
    name: "Antarctica",
    currency: "USD",
    locale: "en-US",
  },
  {
    code: "AG",
    name: "Antigua and Barbuda",
    currency: "XCD",
    locale: "en",
  },
  {
    code: "AR",
    name: "Argentina",
    currency: "ARS",
    locale: "es-AR",
  },
  {
    code: "AM",
    name: "Armenia",
    currency: "AMD",
    locale: "hy-AM",
  },
  {
    code: "AW",
    name: "Aruba",
    currency: "AWG",
    locale: "nl",
  },
  {
    code: "AU",
    name: "Australia",
    currency: "AUD",
    locale: "en-AU",
  },
  {
    code: "AT",
    name: "Austria",
    currency: "EUR",
    locale: "de-AT",
  },
  {
    code: "AZ",
    name: "Azerbaijan",
    currency: "AZN",
    locale: "az-Cyrl-AZ",
  },
  {
    code: "BS",
    name: "Bahamas",
    currency: "BSD",
    locale: "en",
  },
  {
    code: "BH",
    name: "Bahrain",
    currency: "BHD",
    locale: "ar-BH",
  },
  {
    code: "BD",
    name: "Bangladesh",
    currency: "BDT",
    locale: "bn-BD",
  },
  {
    code: "BB",
    name: "Barbados",
    currency: "BBD",
    locale: "en",
  },
  {
    code: "BY",
    name: "Belarus",
    currency: "BYN",
    locale: "be-BY",
  },
  {
    code: "BE",
    name: "Belgium",
    currency: "EUR",
    locale: "nl-BE",
  },
  {
    code: "BZ",
    name: "Belize",
    currency: "BZD",
    locale: "en-BZ",
  },
  {
    code: "BJ",
    name: "Benin",
    currency: "XOF",
    locale: "fr-BJ",
  },
  {
    code: "BM",
    name: "Bermuda",
    currency: "BMD",
    locale: "en",
  },
  {
    code: "BT",
    name: "Bhutan",
    currency: "BTN",
    locale: "dz",
  },
  {
    code: "BO",
    name: "Bolivia",
    currency: "BTN",
    locale: "es-BO",
  },
  {
    code: "BQ",
    name: "Bonaire",
    currency: "USD",
    locale: "nl",
  },
  {
    code: "BA",
    name: "Bosnia and Herzegovina",
    currency: "BAM",
    locale: "bs-BA",
  },
  {
    code: "BW",
    name: "Botswana",
    currency: "BWP",
    locale: "en-BW",
  },
  {
    code: "BV",
    name: "Bouvet Island",
    currency: "NOK",
    locale: "no",
  },
  {
    code: "BR",
    name: "Brazil",
    currency: "BRL",
    locale: "pt-BR",
  },
  {
    code: "IO",
    name: "British Indian Ocean Territory",
    currency: "USD",
    locale: "en",
  },
  {
    code: "BN",
    name: "Brunei Darussalam",
    currency: "BND",
    locale: "ms-BN",
  },
  {
    code: "BG",
    name: "Bulgaria",
    currency: "BGN",
    locale: "bg-BG",
  },
  {
    code: "BF",
    name: "Burkina Faso",
    currency: "XOF",
    locale: "fr-BF",
  },
  {
    code: "BI",
    name: "Burundi",
    currency: "BIF",
    locale: "fr-BI",
  },
  {
    code: "CV",
    name: "Cabo Verde",
    currency: "CVE",
    locale: "kea-CV",
  },
  {
    code: "KH",
    name: "Cambodia",
    currency: "KHR",
    locale: "km-KH",
  },
  {
    code: "CM",
    name: "Cameroon",
    currency: "XAF",
    locale: "fr-CM",
  },
  {
    code: "CA",
    name: "Canada",
    currency: "CAD",
    locale: "en-CA",
  },
  {
    code: "KY",
    name: "Cayman Islands",
    currency: "KYD",
    locale: "en",
  },
  {
    code: "CF",
    name: "Central African Republic",
    currency: "XAF",
    locale: "fr-CF",
  },
  {
    code: "TD",
    name: "Chad",
    currency: "XAF",
    locale: "fr-TD",
  },
  {
    code: "CL",
    name: "Chile",
    currency: "CLF",
    locale: "es-CL",
  },
  {
    code: "CN",
    name: "China",
    currency: "CNY",
    locale: "zh-CN",
  },
  {
    code: "CX",
    name: "Christmas Island",
    currency: "AUD",
    locale: "en",
  },
  {
    code: "CC",
    name: "Cocos Islands",
    currency: "AUD",
    locale: "en",
  },
  {
    code: "CO",
    name: "Colombia",
    currency: "COU",
    locale: "es-CO",
  },
  {
    code: "KM",
    name: "Comoros",
    currency: "KMF",
    locale: "fr-KM",
  },
  {
    code: "CD",
    name: "Democratic Republic of the Congo",
    currency: "CDF",
    locale: "fr-CD",
  },
  {
    code: "CG",
    name: "Congo",
    currency: "XAF",
    locale: "fr-CG",
  },
  {
    code: "CK",
    name: "Cook Islands",
    currency: "NZD",
    locale: "en",
  },
  {
    code: "CR",
    name: "Costa Rica",
    currency: "CRC",
    locale: "es-CR",
  },
  {
    code: "HR",
    name: "Croatia",
    currency: "HRK",
    locale: "hr-HR",
  },
  {
    code: "CU",
    name: "Cuba",
    currency: "CUC",
    locale: "es",
  },
  {
    code: "CW",
    name: "Curaçao",
    currency: "ANG",
    locale: "nl",
  },
  {
    code: "CY",
    name: "Cyprus",
    currency: "EUR",
    locale: "el-CY",
  },
  {
    code: "CZ",
    name: "Czechia",
    currency: "CZK",
    locale: "cs-CZ",
  },
  {
    code: "CI",
    name: "Côte d'Ivoire",
    currency: "CZK",
    locale: "fr-CI",
  },
  {
    code: "DK",
    name: "Denmark",
    currency: "DKK",
    locale: "da-DK",
  },
  {
    code: "DJ",
    name: "Djibouti",
    currency: "DJF",
    locale: "fr-DJ",
  },
  {
    code: "DM",
    name: "Dominica",
    currency: "XCD",
    locale: "en",
  },
  {
    code: "DO",
    name: "Dominican Republic",
    currency: "DOP",
    locale: "es-DO",
  },
  {
    code: "EC",
    name: "Ecuador",
    currency: "USD",
    locale: "es-EC",
  },
  {
    code: "EG",
    name: "Egypt",
    currency: "EGP",
    locale: "ar-EG",
  },
  {
    code: "SV",
    name: "El Salvador",
    currency: "USD",
    locale: "es-SV",
  },
  {
    code: "GQ",
    name: "Equatorial Guinea",
    currency: "XAF",
    locale: "fr-GQ",
  },
  {
    code: "ER",
    name: "Eritrea",
    currency: "ERN",
    locale: "ti-ER",
  },
  {
    code: "EE",
    name: "Estonia",
    currency: "EUR",
    locale: "et-EE",
  },
  {
    code: "SZ",
    name: "Eswatini",
    currency: "EUR",
    locale: "en",
  },
  {
    code: "ET",
    name: "Ethiopia",
    currency: "ETB",
    locale: "am-ET",
  },
  {
    code: "FK",
    name: "Falkland Islands",
    currency: "DKK",
    locale: "en",
  },
  {
    code: "FO",
    name: "Faroe Islands",
    currency: "DKK",
    locale: "fo-FO",
  },
  {
    code: "FJ",
    name: "Fiji",
    currency: "FJD",
    locale: "en",
  },
  {
    code: "FI",
    name: "Finland",
    currency: "EUR",
    locale: "fi-FI",
  },
  {
    code: "FR",
    name: "France",
    currency: "EUR",
    locale: "fr-FR",
  },
  {
    code: "GF",
    name: "French Guiana",
    currency: "EUR",
    locale: "fr",
  },
  {
    code: "PF",
    name: "French Polynesia",
    currency: "XPF",
    locale: "fr",
  },
  {
    code: "TF",
    name: "French Southern Territories",
    currency: "EUR",
    locale: "fr",
  },
  {
    code: "GA",
    name: "Gabon",
    currency: "XAF",
    locale: "fr-GA",
  },
  {
    code: "GM",
    name: "Gambia",
    currency: "GMD",
    locale: "en",
  },
  {
    code: "GE",
    name: "Georgia",
    currency: "GEL",
    locale: "ka-GE",
  },
  {
    code: "DE",
    name: "Germany",
    currency: "EUR",
    locale: "de-DE",
  },
  {
    code: "GH",
    name: "Ghana",
    currency: "GHS",
    locale: "ak-GH",
  },
  {
    code: "GI",
    name: "Gibraltar",
    currency: "GIP",
    locale: "en",
  },
  {
    code: "GR",
    name: "Greece",
    currency: "EUR",
    locale: "el-GR",
  },
  {
    code: "GL",
    name: "Greenland",
    currency: "DKK",
    locale: "kl-GL",
  },
  {
    code: "GD",
    name: "Grenada",
    currency: "XCD",
    locale: "en",
  },
  {
    code: "GP",
    name: "Guadeloupe",
    currency: "EUR",
    locale: "fr-GP",
  },
  {
    code: "GU",
    name: "Guam",
    currency: "USD",
    locale: "en-GU",
  },
  {
    code: "GT",
    name: "Guatemala",
    currency: "GTQ",
    locale: "es-GT",
  },
  {
    code: "GG",
    name: "Guernsey",
    currency: "GBP",
    locale: "en",
  },
  {
    code: "GN",
    name: "Guinea",
    currency: "GNF",
    locale: "fr-GN",
  },
  {
    code: "GW",
    name: "Guinea-Bissau",
    currency: "XOF",
    locale: "pt-GW",
  },
  {
    code: "GY",
    name: "Guyana",
    currency: "GYD",
    locale: "en",
  },
  {
    code: "HT",
    name: "Haiti",
    currency: "USD",
    locale: "fr",
  },
  {
    code: "HM",
    name: "Heard Island and McDonald Islands",
    currency: "AUD",
    locale: "en",
  },
  {
    code: "VA",
    name: "Holy See",
    currency: "EUR",
    locale: "it",
  },
  {
    code: "HN",
    name: "Honduras",
    currency: "HNL",
    locale: "es-HN",
  },
  {
    code: "HK",
    name: "Hong Kong",
    currency: "HKD",
    locale: "en-HK",
  },
  {
    code: "HU",
    name: "Hungary",
    currency: "HUF",
    locale: "hu-HU",
  },
  {
    code: "IS",
    name: "Iceland",
    currency: "ISK",
    locale: "is-IS",
  },
  {
    code: "IN",
    name: "India",
    currency: "INR",
    locale: "hi-IN",
  },
  {
    code: "ID",
    name: "Indonesia",
    currency: "IDR",
    locale: "id-ID",
  },
  {
    code: "IR",
    name: "Iran",
    currency: "XDR",
    locale: "fa-IR",
  },
  {
    code: "IQ",
    name: "Iraq",
    currency: "IQD",
    locale: "ar-IQ",
  },
  {
    code: "IE",
    name: "Ireland",
    currency: "EUR",
    locale: "en-IE",
  },
  {
    code: "IM",
    name: "Isle of Man",
    currency: "GBP",
    locale: "en",
  },
  {
    code: "IL",
    name: "Israel",
    currency: "ILS",
    locale: "he-IL",
  },
  {
    code: "IT",
    name: "Italy",
    currency: "EUR",
    locale: "it-IT",
  },
  {
    code: "JM",
    name: "Jamaica",
    currency: "JMD",
    locale: "en-JM",
  },
  {
    code: "JP",
    name: "Japan",
    currency: "JPY",
    locale: "ja-JP",
  },
  {
    code: "JE",
    name: "Jersey",
    currency: "GBP",
    locale: "en",
  },
  {
    code: "JO",
    name: "Jordan",
    currency: "JOD",
    locale: "ar-JO",
  },
  {
    code: "KZ",
    name: "Kazakhstan",
    currency: "KZT",
    locale: "kk-Cyrl-KZ",
  },
  {
    code: "KE",
    name: "Kenya",
    currency: "KES",
    locale: "ebu-KE",
  },
  {
    code: "KI",
    name: "Kiribati",
    currency: "AUD",
    locale: "en",
  },
  {
    code: "KP",
    name: "North Korea",
    currency: "KPW",
    locale: "ko",
  },
  {
    code: "KR",
    name: "South Korea",
    currency: "KRW",
    locale: "ko-KR",
  },
  {
    code: "KW",
    name: "Kuwait",
    currency: "KWD",
    locale: "ar-KW",
  },
  {
    code: "KG",
    name: "Kyrgyzstan",
    currency: "KGS",
    locale: "ky",
  },
  {
    code: "LA",
    name: "Lao People's Democratic Republic",
    currency: "LAK",
    locale: "lo",
  },
  {
    code: "LV",
    name: "Latvia",
    currency: "EUR",
    locale: "lv-LV",
  },
  {
    code: "LB",
    name: "Lebanon",
    currency: "LBP",
    locale: "ar-LB",
  },
  {
    code: "LS",
    name: "Lesotho",
    currency: "ZAR",
    locale: "en",
  },
  {
    code: "LR",
    name: "Liberia",
    currency: "LRD",
    locale: "en",
  },
  {
    code: "LY",
    name: "Libya",
    currency: "LYD",
    locale: "ar-LY",
  },
  {
    code: "LI",
    name: "Liechtenstein",
    currency: "CHF",
    locale: "de-LI",
  },
  {
    code: "LT",
    name: "Lithuania",
    currency: "EUR",
    locale: "lt-LT",
  },
  {
    code: "LU",
    name: "Luxembourg",
    currency: "EUR",
    locale: "fr-LU",
  },
  {
    code: "MO",
    name: "Macao",
    currency: "MOP",
    locale: "zh-Hans-MO",
  },
  {
    code: "MG",
    name: "Madagascar",
    currency: "MGA",
    locale: "fr-MG",
  },
  {
    code: "MW",
    name: "Malawi",
    currency: "MWK",
    locale: "en",
  },
  {
    code: "MY",
    name: "Malaysia",
    currency: "MYR",
    locale: "ms-MY",
  },
  {
    code: "MV",
    name: "Maldives",
    currency: "MVR",
    locale: "dv",
  },
  {
    code: "ML",
    name: "Mali",
    currency: "XOF",
    locale: "fr-ML",
  },
  {
    code: "MT",
    name: "Malta",
    currency: "EUR",
    locale: "en-MT",
  },
  {
    code: "MH",
    name: "Marshall Islands",
    currency: "USD",
    locale: "en-MH",
  },
  {
    code: "MQ",
    name: "Martinique",
    currency: "EUR",
    locale: "fr-MQ",
  },
  {
    code: "MR",
    name: "Mauritania",
    currency: "MRU",
    locale: "ar",
  },
  {
    code: "MU",
    name: "Mauritius",
    currency: "MUR",
    locale: "en-MU",
  },
  {
    code: "YT",
    name: "Mayotte",
    currency: "EUR",
    locale: "fr",
  },
  {
    code: "MX",
    name: "Mexico",
    currency: "MXV",
    locale: "es-MX",
  },
  {
    code: "FM",
    name: "Micronesia",
    currency: "RUB",
    locale: "en",
  },
  {
    code: "MD",
    name: "Moldova",
    currency: "MDL",
    locale: "ro-MD",
  },
  {
    code: "MC",
    name: "Monaco",
    currency: "EUR",
    locale: "fr-MC",
  },
  {
    code: "MN",
    name: "Mongolia",
    currency: "MNT",
    locale: "mn",
  },
  {
    code: "ME",
    name: "Montenegro",
    currency: "EUR",
    locale: "sr-Cyrl-ME",
  },
  {
    code: "MS",
    name: "Montserrat",
    currency: "XCD",
    locale: "en",
  },
  {
    code: "MA",
    name: "Morocco",
    currency: "MAD",
    locale: "ar-MA",
  },
  {
    code: "MZ",
    name: "Mozambique",
    currency: "MZN",
    locale: "pt-MZ",
  },
  {
    code: "MM",
    name: "Myanmar",
    currency: "MMK",
    locale: "my-MM",
  },
  {
    code: "NA",
    name: "Namibia",
    currency: "ZAR",
    locale: "en-NA",
  },
  {
    code: "NR",
    name: "Nauru",
    currency: "AUD",
    locale: "en",
  },
  {
    code: "NP",
    name: "Nepal",
    currency: "NPR",
    locale: "ne-NP",
  },
  {
    code: "NL",
    name: "Netherlands",
    currency: "EUR",
    locale: "nl-NL",
  },
  {
    code: "AN",
    name: "Netherlands Antilles",
    currency: "ANG",
    locale: "nl-AN",
  },
  {
    code: "NC",
    name: "New Caledonia",
    currency: "XPF",
    locale: "fr",
  },
  {
    code: "NZ",
    name: "New Zealand",
    currency: "NZD",
    locale: "en-NZ",
  },
  {
    code: "NI",
    name: "Nicaragua",
    currency: "NIO",
    locale: "es-NI",
  },
  {
    code: "NE",
    name: "Niger",
    currency: "XOF",
    locale: "fr-NE",
  },
  {
    code: "NG",
    name: "Nigeria",
    currency: "NGN",
    locale: "ha-Latn-NG",
  },
  {
    code: "NU",
    name: "Niue",
    currency: "NZD",
    locale: "en",
  },
  {
    code: "NF",
    name: "Norfolk Island",
    currency: "AUD",
    locale: "en",
  },
  {
    code: "MK",
    name: "North Macedonia",
    currency: "AUD",
    locale: "mk-MK",
  },
  {
    code: "MP",
    name: "Northern Mariana Islands",
    currency: "USD",
    locale: "en-MP",
  },
  {
    code: "NO",
    name: "Norway",
    currency: "NOK",
    locale: "nb-NO",
  },
  {
    code: "OM",
    name: "Oman",
    currency: "OMR",
    locale: "ar-OM",
  },
  {
    code: "PK",
    name: "Pakistan",
    currency: "PKR",
    locale: "en-PK",
  },
  {
    code: "PW",
    name: "Palau",
    currency: "USD",
    locale: "en",
  },
  {
    code: "PS",
    name: "Palestine",
    currency: "USD",
    locale: "ar",
  },
  {
    code: "PA",
    name: "Panama",
    currency: "USD",
    locale: "es-PA",
  },
  {
    code: "PG",
    name: "Papua New Guinea",
    currency: "PGK",
    locale: "en",
  },
  {
    code: "PY",
    name: "Paraguay",
    currency: "PYG",
    locale: "es-PY",
  },
  {
    code: "PE",
    name: "Peru",
    currency: "PEN",
    locale: "es-PE",
  },
  {
    code: "PH",
    name: "Philippines",
    currency: "PHP",
    locale: "en-PH",
  },
  {
    code: "PN",
    name: "Pitcairn",
    currency: "NZD",
    locale: "en",
  },
  {
    code: "PL",
    name: "Poland",
    currency: "PLN",
    locale: "pl-PL",
  },
  {
    code: "PT",
    name: "Portugal",
    currency: "EUR",
    locale: "pt-PT",
  },
  {
    code: "PR",
    name: "Puerto Rico",
    currency: "USD",
    locale: "es-PR",
  },
  {
    code: "QA",
    name: "Qatar",
    currency: "QAR",
    locale: "ar-QA",
  },
  {
    code: "RO",
    name: "Romania",
    currency: "RON",
    locale: "ro-RO",
  },
  {
    code: "RU",
    name: "Russia",
    currency: "RUB",
    locale: "ru-RU",
  },
  {
    code: "RW",
    name: "Rwanda",
    currency: "RWF",
    locale: "fr-RW",
  },
  {
    code: "RE",
    name: "Réunion",
    currency: "RWF",
    locale: "fr-RE",
  },
  {
    code: "BL",
    name: "Saint Barthélemy",
    currency: "EUR",
    locale: "fr-BL",
  },
  {
    code: "SH",
    name: "Saint Helena",
    currency: "SHP",
    locale: "en",
  },
  {
    code: "KN",
    name: "Saint Kitts and Nevis",
    currency: "XCD",
    locale: "en",
  },
  {
    code: "LC",
    name: "Saint Lucia",
    currency: "XCD",
    locale: "en",
  },
  {
    code: "MF",
    name: "Saint Martin",
    currency: "EUR",
    locale: "fr-MF",
  },
  {
    code: "PM",
    name: "Saint Pierre and Miquelon",
    currency: "EUR",
    locale: "fr",
  },
  {
    code: "VC",
    name: "Saint Vincent and the Grenadines",
    currency: "XCD",
    locale: "en",
  },
  {
    code: "WS",
    name: "Samoa",
    currency: "WST",
    locale: "sm",
  },
  {
    code: "SM",
    name: "San Marino",
    currency: "EUR",
    locale: "it",
  },
  {
    code: "ST",
    name: "Sao Tome and Principe",
    currency: "STN",
    locale: "pt",
  },
  {
    code: "SA",
    name: "Saudi Arabia",
    currency: "SAR",
    locale: "ar-SA",
  },
  {
    code: "SN",
    name: "Senegal",
    currency: "XOF",
    locale: "fr-SN",
  },
  {
    code: "RS",
    name: "Serbia",
    currency: "RSD",
    locale: "sr-Cyrl-RS",
  },
  {
    code: "SC",
    name: "Seychelles",
    currency: "SCR",
    locale: "fr",
  },
  {
    code: "SL",
    name: "Sierra Leone",
    currency: "SLL",
    locale: "en",
  },
  {
    code: "SG",
    name: "Singapore",
    currency: "SGD",
    locale: "en-SG",
  },
  {
    code: "SX",
    name: "Sint Maarten",
    currency: "ANG",
    locale: "nl",
  },
  {
    code: "SK",
    name: "Slovakia",
    currency: "EUR",
    locale: "sk-SK",
  },
  {
    code: "SI",
    name: "Slovenia",
    currency: "EUR",
    locale: "sl-SI",
  },
  {
    code: "SB",
    name: "Solomon Islands",
    currency: "SBD",
    locale: "en",
  },
  {
    code: "SO",
    name: "Somalia",
    currency: "SOS",
    locale: "so-SO",
  },
  {
    code: "ZA",
    name: "South Africa",
    currency: "ZAR",
    locale: "af-ZA",
  },
  {
    code: "GS",
    name: "South Georgia and the South Sandwich Islands",
    currency: "USD",
    locale: "en",
  },
  {
    code: "SS",
    name: "South Sudan",
    currency: "SSP",
    locale: "en",
  },
  {
    code: "ES",
    name: "Spain",
    currency: "EUR",
    locale: "es-ES",
  },
  {
    code: "LK",
    name: "Sri Lanka",
    currency: "LKR",
    locale: "si-LK",
  },
  {
    code: "SD",
    name: "Sudan",
    currency: "SDG",
    locale: "ar-SD",
  },
  {
    code: "SR",
    name: "Suriname",
    currency: "SRD",
    locale: "nl",
  },
  {
    code: "SJ",
    name: "Svalbard and Jan Mayen",
    currency: "NOK",
    locale: "no",
  },
  {
    code: "SE",
    name: "Sweden",
    currency: "SEK",
    locale: "sv-SE",
  },
  {
    code: "CH",
    name: "Switzerland",
    currency: "CHW",
    locale: "fr-CH",
  },
  {
    code: "SY",
    name: "Syrian Arab Republic",
    currency: "SYP",
    locale: "ar-SY",
  },
  {
    code: "TW",
    name: "Taiwan",
    currency: "TWD",
    locale: "zh-Hant-TW",
  },
  {
    code: "TJ",
    name: "Tajikistan",
    currency: "TJS",
    locale: "tg",
  },
  {
    code: "TZ",
    name: "Tanzania",
    currency: "TZS",
    locale: "asa-TZ",
  },
  {
    code: "TH",
    name: "Thailand",
    currency: "THB",
    locale: "th-TH",
  },
  {
    code: "TL",
    name: "Timor-Leste",
    currency: "USD",
    locale: "pt",
  },
  {
    code: "TG",
    name: "Togo",
    currency: "XOF",
    locale: "fr-TG",
  },
  {
    code: "TK",
    name: "Tokelau",
    currency: "NZD",
    locale: "en",
  },
  {
    code: "TO",
    name: "Tonga",
    currency: "TOP",
    locale: "to-TO",
  },
  {
    code: "TT",
    name: "Trinidad and Tobago",
    currency: "TTD",
    locale: "en-TT",
  },
  {
    code: "TN",
    name: "Tunisia",
    currency: "TND",
    locale: "ar-TN",
  },
  {
    code: "TR",
    name: "Turkey",
    currency: "TRY",
    locale: "tr-TR",
  },
  {
    code: "TM",
    name: "Turkmenistan",
    currency: "TMT",
    locale: "tk",
  },
  {
    code: "TC",
    name: "Turks and Caicos Islands",
    currency: "USD",
    locale: "en",
  },
  {
    code: "TV",
    name: "Tuvalu",
    currency: "AUD",
    locale: "en",
  },
  {
    code: "UG",
    name: "Uganda",
    currency: "UGX",
    locale: "cgg-UG",
  },
  {
    code: "UA",
    name: "Ukraine",
    currency: "UAH",
    locale: "uk-UA",
  },
  {
    code: "AE",
    name: "United Arab Emirates",
    currency: "AED",
    locale: "ar-AE",
  },
  {
    code: "GB",
    name: "United Kingdom",
    currency: "GBP",
    locale: "en-GB",
  },
  {
    code: "UM",
    name: "United States Minor Outlying Islands",
    currency: "USD",
    locale: "en-UM",
  },
  {
    code: "US",
    name: "United States",
    currency: "USD",
    locale: "en-US",
  },
  {
    code: "UY",
    name: "Uruguay",
    currency: "UYU",
    locale: "es-UY",
  },
  {
    code: "UZ",
    name: "Uzbekistan",
    currency: "UZS",
    locale: "uz-Cyrl-UZ",
  },
  {
    code: "VU",
    name: "Vanuatu",
    currency: "VUV",
    locale: "bi",
  },
  {
    code: "VE",
    name: "Venezuela",
    currency: "VUV",
    locale: "es-VE",
  },
  {
    code: "VN",
    name: "Viet Nam",
    currency: "VND",
    locale: "vi-VN",
  },
  {
    code: "VG",
    name: "Virgin Islands (British)",
    currency: "USD",
    locale: "en",
  },
  {
    code: "VI",
    name: "Virgin Islands (U.S.)",
    currency: "USD",
    locale: "en-VI",
  },
  {
    code: "WF",
    name: "Wallis and Futuna",
    currency: "XPF",
    locale: "fr",
  },
  {
    code: "EH",
    name: "Western Sahara",
    currency: "MAD",
    locale: "es",
  },
  {
    code: "YE",
    name: "Yemen",
    currency: "YER",
    locale: "ar-YE",
  },
  {
    code: "ZM",
    name: "Zambia",
    currency: "ZMW",
    locale: "bem-ZM",
  },
  {
    code: "ZW",
    name: "Zimbabwe",
    currency: "ZWL",
    locale: "en-ZW",
  },
  {
    code: "AX",
    name: "Åland Islands",
    currency: "EUR",
    locale: "sv",
  },
  {
    code: "XK",
    name: "Kosovo",
    currency: "EUR",
    locale: "sq",
  },
];

