import { yupResolver } from "@hookform/resolvers/yup";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import ExcelFile from "../../assets/downloadtemplate.xlsx";
import RRCurrencyInput from "../../common/components/CurrencyInput";
import RRPhoneInput from "../../common/components/PhoneInput";
import {
  currencyData,
  DataProtection,
  industryData,
  loanPurposeData,
  TermsConditions,
} from "../../common/constants";
import datehelper from "../../common/helper/datehelper";
import Notification from "../../common/notification/notification.component";
import { validationSchema } from "../../schemas/loanAppValidationSchema";
import ApiService, { ApiUrls } from "../../service/ApiService";
import ConfirmModal from "./ConfirmModal";
import RRTextField from "../../common/components/TextField";
import { Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { NonSGLoanApplicationDocument } from "../../common/constants/non-sg-loan.constant";

const LoanApp = () => {
  const regex = {
    alphabetOnly: /^(?:(?=^.*([a-z]|[A-Z]))(([a-z]|[A-Z]))|([a-z]|[A-Z]))/g,
    alphaNumeric: /^[A-Za-z0-9]+$/,
    alphabetAndNumber:
      /^(?:(?=^.*([0-9]|[a-z]|[A-Z]))(([0-9]|[a-z]|[A-Z]))|([0-9]|[a-z]|[A-Z]))/g,
    alphaNumericHyphenAndForwardSlash: /^[A-Za-z0-9\/-]+$/,
    alphaNumericHyphenAndPlus: /^[A-Za-z0-9+-]+$/,
  };

  const isRRF = window.location.href.toLocaleLowerCase().includes("rrf");
  const businessRegCertRef = useRef();
  const bankStatementsRef = useRef();
  const financeStatementsRef = useRef();
  const gstStatementsRef = useRef();
  const passport1Ref = useRef();
  const utilityBills1Ref = useRef();
  const revenueFinancingRef = useRef();
  const passport2Ref = useRef();
  const utilityBills2Ref = useRef();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [dateValue, setDateValue] = useState({
    businessDate: null,
    videoCallDate1: null,
    videoCallDate2: null,
  });
  const [hideProgress, setHideProgress] = useState(true);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [country, setCountry] = useState("");
  const [isFileChanged, setFileChanged] = useState(false);
  const [timeValue, setTimeValue] = useState({
    videoCallTime1: null,
    videoCallTime2: null,
  });
  const [secondDirector, setSecondDirector] = useState(false);
  const director2Schema = {
    directorName2: Yup.string()
      .required("Director name is required.")
      .matches(regex.alphabetOnly, "Please enter valid director name."),

    personalIdentificationNumber2: Yup.string()
      .required("Personal identification number is required.")
      .typeError("Personal identification number is required.")
      .matches(
        regex.alphaNumericHyphenAndPlus,
        "Please enter valid personal identification number."
      ),

    shareholding2: Yup.number()
      .required("Shareholding is required.")
      .typeError("Shareholding is required.")
      .moreThan("0", "Share holding should be a between 1 and 100.")
      .lessThan("101", "Share holding should be a between 1 and 100."),

    directorAddress2: Yup.string()
      .required("Residential address is required.")
      .matches(
        regex.alphabetAndNumber,
        "Please enter valid residential address."
      )
      .max(500, "Residential address must be less than 500 characters."),

    directorZipCode2: Yup.string()
      .required("Zipcode is required.")
      .matches(regex.alphaNumeric, "Please enter valid zipcode."),

    directorCountry2: Yup.object().shape({
      country: Yup.string().required("Country is required."),
      countryCode: Yup.string().required("Country is required."),
    }),

    directorPhone2: Yup.string().required("Phone number is required."),

    directorEmail2: Yup.string()
      .email("Please enter valid email address.")
      .required("Email address is required.")
      .when(["directorEmail1"], (directorEmail1, schema) => {
        return schema.notOneOf(
          [directorEmail1],
          "This email address is already enterd for director 1."
        );
      }),

    videoCallDate2: Yup.date()
      .required("Video call date is required.")
      .typeError("Please enter valid registration video call date.")
      .min(datehelper.getTodayDate(), "Video call date should be future date."),

    videoCallTime2: Yup.date()
      .required("Video call time is required.")
      .typeError("Please enter valid registration video call time."),

    passport2: Yup.mixed()
      .test("required.", "Please upload passport images/pdf.", (file) => {
        if (file && file.length) return true;
        else return false;
      })
      .test(
        "required.",
        "Please upload pdf, png, jpeg or jpg files only",
        (file) => {
          if (file && file.length) {
            var inValidFiles = [];
            for (var f = 0; f < file.length; f++) {
              if (
                _.includes(
                  ["pdf", "png", "jpeg", "jpg"],
                  _.toLower(_.last(_.split(file[f]?.name, ".")))
                ) === false
              ) {
                inValidFiles.push(file[f]);
              }
            }
            if (inValidFiles.length > 0) {
              return false;
            } else {
              return true;
            }
          }
        }
      )
      .test("required", "You can upload maximum 5 passports.", (file) => {
        if (file && file.length > 5) {
          return false;
        } else {
          return true;
        }
      })
      .test("required.", "File with this name already exists.", (file) => {
        if (file && file.length) {
          const isValid = hasDuplicates(_.map(file, "name"));
          return !isValid;
        } else {
          return true;
        }
      }),
    utilityBills2: Yup.mixed()
      .test("required.", "Please upload utility bills", (file) => {
        if (file && file.length) return true;
        else return false;
      })
      .test(
        "required.",
        "Please upload pdf, png, jpeg or jpg files only",
        (file) => {
          if (file && file.length) {
            var inValidFiles = [];
            for (var f = 0; f < file.length; f++) {
              if (
                _.includes(
                  ["pdf", "png", "jpeg", "jpg"],
                  _.toLower(_.last(_.split(file[f]?.name, ".")))
                ) === false
              ) {
                inValidFiles.push(file[f]);
              }
            }
            if (inValidFiles.length > 0) {
              return false;
            } else {
              return true;
            }
          }
        }
      )
      .test("required", "You can upload maximum 5 utility bills.", (file) => {
        if (file && file.length > 5) {
          return false;
        } else {
          return true;
        }
      })
      .test("required.", "File with this name already exists.", (file) => {
        if (file && file.length) {
          const isValid = hasDuplicates(_.map(file, "name"));
          return !isValid;
        } else {
          return true;
        }
      }),
  };
  const [director2FormSchema, setDirector2FormSchema] = useState({});
  // const [formData, setFormData] = useState();
  function hasDuplicates(a) {
    return _.uniq(a).length !== a.length;
  }

  useEffect(() => {
    fileList();
  }, [isFileChanged]);

  useEffect(() => {
    if (!secondDirector) {
      setDirector2FormSchema(director2Schema);
    } else {
      setDirector2FormSchema({});
    }
  }, [secondDirector]);

  const formSchema = isRRF
    ? Yup.object().shape({
        ...validationSchema,
        ...director2FormSchema,
        revenueFinancing: Yup.mixed()
          .test(
            "required",
            "Please upload Revenue Detail Document.",
            (file) => {
              if (file && file.length) return true;
              else return false;
            }
          )
          .test(
            "required.",
            "Please upload excel file(.xlsx) only.",
            (file) => {
              if (file && file.length) {
                var inValidFiles = [];
                for (var f = 0; f < file.length; f++) {
                  if (
                    _.includes(
                      ["xlsx"],
                      _.toLower(_.last(_.split(file[f]?.name, ".")))
                    ) === false
                  ) {
                    inValidFiles.push(file[f]);
                  }
                }
                if (inValidFiles.length > 0) {
                  return false;
                } else {
                  return true;
                }
              }
            }
          )
          .test("required.", "File with this name already exists.", (file) => {
            if (file && file.length) {
              const isValid = hasDuplicates(_.map(file, "name"));
              return !isValid;
            } else {
              return true;
            }
          }),
      })
    : !secondDirector
    ? Yup.object().shape({
        ...validationSchema,
        ...director2FormSchema,
      })
    : Yup.object().shape({ ...validationSchema });

  const {
    register,
    setValue,
    getValues,
    resetField,
    reset,
    handleSubmit,
    formState: { errors, submitCount },
  } = useForm({
    resolver: yupResolver(formSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: {},
  });

  const [business, setBusinessInfo] = useState({
    certificate: null,
    regNum: "",
    name: "",
    regDate: "",
    country: "",
    industry: "",
    address: "",
    zipCode: "",
    email: "",
    phone: "",
    day: "",
    month: "",
    year: "",
  });

  const onDateChange = (value, fieldName) => {
    if (value) {
      setDateValue({
        ...dateValue,
        [fieldName]: value,
      });
      setValue(fieldName, datehelper.formatedDate(value), {
        shouldValidate: true,
      });
    }
  };

  const onTimeChange = (value, fieldName) => {
    if (value) {
      setTimeValue({
        ...timeValue,
        [fieldName]: value,
      });
      setValue(fieldName, datehelper.formatedDate(value), {
        shouldValidate: true,
      });
    }
  };

  const onCountrySelect = (countryCode, id) => {
    if (id === "locationSelect") {
      const countryData = _.filter(currencyData, ["code", countryCode]);
      setCountry(countryData[0]);
      setValue(
        "businessCountry",
        {
          countryCode: countryCode,
          country: _.find(currencyData, function (c) {
            return c.code === countryCode;
          })?.name,
        },
        {
          shouldValidate: true,
          shouldDirty: true,
        }
      );
    }
    if (id) {
      setValue(
        id,
        {
          countryCode: countryCode,
          country: _.find(currencyData, function (c) {
            return c.code === countryCode;
          })?.name,
        },
        {
          shouldValidate: true,
          shouldDirty: true,
        }
      );
    }
  };

  const mergeDate = (date, time) => {
    var callDate = new Date();
    callDate.setDate(date.getDate());
    callDate.setMonth(date.getMonth());
    callDate.setFullYear(date.getFullYear());
    callDate.setHours(time.getHours(), time.getMinutes());
    return callDate;
  };

  const onConfirm = async () => {};

  const onSubmit = async (formData) => {
    setHideProgress(false);

    const locationPayload = {
      country: formData.locationSelect.country,
      loanType: isRRF ? "RRF" : "PLD",
    };
    const loanPayload = {
      amount: formData.loanAmount,
      tenure: formData.loanTenure,
      purpose: formData.loanPurpose,
    };
    const businessPayload = {
      regNo: formData.businessRegNum,
      name: formData.businessName,
      regDate: formData.businessDate,
      incorporation: formData.businessCountry?.country,
      country: formData.businessAddrCountry?.country,
      industry: business.industry,
      address: formData.businessAddr,
      zipCode: formData.businessZipCode,
      email: formData.businessEmail,
      phone: formData.businessPhone,
      state: formData.businessState,
    };

    const applicantPayload = {
      name: formData.applicantName,
      nationality: formData.applicantNationality?.country,
      resStatus: formData.residentialStatus,
      address: formData.residentialAddr,
      zipCode: formData.applicantZip,
      email: formData.applicantEmail,
      phone: formData.applicantPhone,
    };
    const statementsPayload = {
      bank: formData.bankStatements,
      financial: formData.financeStatements,
      revenueFinancing: formData.revenueFinancing,
    };

    const directorPayload = !secondDirector
      ? [
          {
            directorName: formData.directorName1,
            personalIdentificationNumber:
              formData.personalIdentificationNumber1,
            shareholding: formData.shareholding1,
            address: formData.directorAddress1,
            zipCode: formData.directorZipCode1,
            email: formData.directorEmail1,
            phone: formData.directorPhone1,
            country: formData.directorCountry1?.country,
            videoCallDate: mergeDate(
              dateValue.videoCallDate1,
              timeValue.videoCallTime1
            ),
          },
          {
            directorName: formData.directorName2,
            personalIdentificationNumber:
              formData.personalIdentificationNumber2,
            shareholding: formData.shareholding2,
            address: formData.directorAddress2,
            zipCode: formData.directorZipCode2,
            email: formData.directorEmail2,
            phone: formData.directorPhone2,
            country: formData.directorCountry2?.country,
            videoCallDate: mergeDate(
              dateValue.videoCallDate2,
              timeValue.videoCallTime2
            ),
          },
        ]
      : [
          {
            directorName: formData.directorName1,
            personalIdentificationNumber:
              formData.personalIdentificationNumber1,
            shareholding: formData.shareholding1,
            address: formData.directorAddress1,
            zipCode: formData.directorZipCode1,
            email: formData.directorEmail1,
            phone: formData.directorPhone1,
            country: formData.directorCountry1?.country,
            videoCallDate: mergeDate(
              dateValue.videoCallDate1,
              timeValue.videoCallTime1
            ),
          },
        ];

    const payload = {
      location: locationPayload,
      loan: loanPayload,
      business: businessPayload,
      applicant: applicantPayload,
      directors: directorPayload,
    };

    const apiData = new FormData();
    apiData.append("formData", JSON.stringify(payload));
    for (var b = 0; b < statementsPayload.bank.length; b++) {
      apiData.append(
        NonSGLoanApplicationDocument.BANK_STATEMENTS,
        statementsPayload.bank[b]
      );
    }
    if (statementsPayload.financial && statementsPayload.financial.length) {
      for (var f = 0; f < statementsPayload.financial.length; f++) {
        apiData.append(
          NonSGLoanApplicationDocument.FINANCIAL_STATEMENTS,
          statementsPayload.financial[f]
        );
      }
    }
    for (var p1 = 0; p1 < formData.passport1.length; p1++) {
      apiData.append(
        NonSGLoanApplicationDocument.DIRECTOR1_PASSPORT,
        formData.passport1[p1]
      );
    }
    for (var u1 = 0; u1 < formData.utilityBills1.length; u1++) {
      apiData.append(
        NonSGLoanApplicationDocument.DIRECTOR1_UTILITY_BILLS,
        formData.utilityBills1[u1]
      );
    }
    if (!secondDirector) {
      for (var p2 = 0; p2 < formData.passport2.length; p2++) {
        apiData.append(
          NonSGLoanApplicationDocument.DIRECTOR2_PASSPORT,
          formData.passport2[p2]
        );
      }
      for (var u2 = 0; u2 < formData.utilityBills2.length; u2++) {
        apiData.append(
          NonSGLoanApplicationDocument.DIRECTOR2_UTILITY_BILLS,
          formData.utilityBills2[u2]
        );
      }
    }
    if (formData.gstStatements && formData.gstStatements.length) {
      for (var g = 0; g < formData.gstStatements.length; g++) {
        apiData.append(
          NonSGLoanApplicationDocument.GST_STATEMENTS,
          formData.gstStatements[g]
        );
      }
    }

    if (isRRF) {
      apiData.append(
        NonSGLoanApplicationDocument.REVENUE_FINANCING,
        statementsPayload.revenueFinancing[0]
      );
    }
    if (formData.businessRegCert && formData.businessRegCert.length) {
      for (var b = 0; b < formData.businessRegCert.length; b++) {
        apiData.append(
          NonSGLoanApplicationDocument.BUSINESS_REGISTRATION_CERTIFICATE,
          formData.businessRegCert[b]
        );
      }
    }
    const apiResponse = await ApiService.post(ApiUrls.LoanApplication, apiData);

    if (apiResponse && apiResponse.status === 200) {
      navigate(
        `/ThankYou/${applicantPayload.name}/${applicantPayload.phone}/${apiResponse?.data}`
      );
    } else {
      setOpen(true);
      setSeverity("error");
      setMessage(`An error occurred. Please try again later.`);
      setHideProgress(true);
    }
  };

  const closeAlert = () => {
    setOpen(false);
  };

  function renderErrorDesc(key, isError = true, subKey = "") {
    return (
      (errors?.[key]?.[subKey]?.message || errors?.[key]?.message) && (
        <p
          className={`form-input-desc${isError ? "-error" : ""}`}
          style={{ marginBottom: "5px" }}
        >
          {subKey ? errors?.[key]?.[subKey]?.message : errors?.[key]?.message}
        </p>
      )
    );
  }

  const fileList = (fieldName, ref) => {
    const files = getValues(`${fieldName}`);
    if (files?.length > 0) {
      return (
        <>
          {Object.keys(files).map((file) => {
            return (
              <Chip
                sx={{ mt: "5px", mr: "5px" }}
                label={files[file].name}
                key={file}
                onDelete={() => onRemove(files[file], fieldName, ref, file)}
              />
            );
          })}
        </>
      );
    }
    return null;
  };

  const onRemove = (file, fieldName, ref, index) => {
    console.log(index);

    setFileChanged(!isFileChanged);
    const files = getValues(`${fieldName}`);
    files.splice(index, 1);
    const setFiles = _.cloneDeep(files);

    if (setFiles.length === 0) {
      ref.current.value = null;
    }
    resetField(fieldName);
    setValue(fieldName, setFiles, { shouldValidate: true });
  };

  const handleThankyouModalClose = () => {
    setConfirmModalOpen(false);
  };

  const onPhoneNumberChange = (e, id) => {
    if (e.target.value) {
      setValue(id, e.target.value, { shouldValidate: true });
    } else {
      setValue(id, e.target.value, { shouldValidate: false });
    }
  };

  const onCurrencyChange = (value, name) => {
    if (value) {
      setValue(name, _.toNumber(value), {
        shouldValidate: true,
        shouldDirty: true,
      });
      setValue("financeStatements", getValues("financeStatements"), {
        shouldValidate: true,
        shouldDirty: true,
      });
    } else {
      resetField(name);
    }
  };

  const onChangeFile = (e) => {
    const fileId = e.target.id;
    let uploadedFiles = [];
    if (e.target.multiple) {
      uploadedFiles = getValues(fileId) || [];
      Object.keys(e.target.files).map((index) => {
        uploadedFiles.push(e.target.files[index]);
      });
    } else {
      if (e.target.files && e.target.files.length > 0) {
        uploadedFiles = e.target.files;
      } else {
        uploadedFiles = getValues(fileId) || [];
      }
    }
    setFileChanged(!isFileChanged);
    e.preventDefault();
    setValue(fileId, uploadedFiles, {
      shouldValidate: true,
    });
  };

  return (
    <div style={{ overflow: "hidden" }} className="loanAppForm">
      {!open && !hideProgress ? (
        <Backdrop sx={{ color: "#fff", zIndex: 9999 }} open={!open}>
          <Stack spacing={1} alignItems="center">
            <CircularProgress color="inherit" />
          </Stack>
        </Backdrop>
      ) : (
        <Notification
          open={open}
          severity={severity}
          message={message}
          closeAlert={closeAlert}
        />
      )}
      <div className="container-fluid">
        {confirmModalOpen ? (
          <ConfirmModal
            confirmModalOpen={confirmModalOpen}
            handleThankyouModalClose={handleThankyouModalClose}
            onConfirm={onConfirm}
          />
        ) : null}
        <div className="row">
          <div
            className="col-xl-12 col-lg-12 content-header loanAppHeader"
            style={{ padding: "27px 0", display: "table", width: "100%" }}
          >
            <div className="container">
              <div className="logo" style={{ float: "left" }}>
                <img src="img/logo_white.svg" height={78} alt="logo"></img>
              </div>
              <div
                className="content-left-wrapper headerContent"
                style={{
                  float: "right",
                  fontSize: "28px",
                  padding: "19px 0",
                  fontWeight: 500,
                }}
              >
                Loan Application Form
              </div>
            </div>
          </div>
          <div
            className="col-xl-12 col-lg-12 wizard_wrapper"
            id="start"
            style={{ background: "#F5F8FA" }}
          >
            <div
              className="container"
              style={{
                background: "#fff",
                padding: "30px",
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
              }}
            >
              <div
                style={{
                  margin: "-30px",
                  padding: "30px",
                  marginBottom: "30px",
                  borderBottom: "1px dashed #ccc",
                  background: " #fff4ed",
                }}
              >
                <h5>
                  {isRRF
                    ? `You are applying for a Recurring Revenue Financing with Credilinq.Ai Pte Ltd`
                    : `You are applying for a PayLater Direct with Credilinq.Ai Pte
                    Ltd`}
                </h5>
              </div>
              <form
                // id="wrapped"
                // method="post"
                // encType="multipart/form-data"
                onSubmit={handleSubmit(onSubmit)}
              >
                <Box
                  sx={{
                    '[class*="ReactFlagsSelect-module_selectBtn"]': {
                      padding: "7px 10px",
                    },
                    '[class*="ReactFlagsSelect-module_selectValue"]': {
                      flexGrow: 5,
                    },
                    'ul[class*="ReactFlagsSelect-module_selectOptions"]': {
                      margin: 0,
                    },
                  }}
                  id="middle-lg-wizard  d-flex justify-content-center col-xl-12"
                >
                  <div className="form-group justify-content-center ">
                    <div className="form-group mx-auto ">
                      <Box
                        sx={{
                          background: {
                            xs: "#601A79",
                            sm: "#601A79",
                          },
                          py: 1,
                          px: 2,
                          fontSize: { xs: 16, md: 20 },
                          color: "#fff",
                          borderRadius: "5px",
                          my: 2,
                        }}
                      >
                        Location<span className="text-danger">*</span>
                      </Box>

                      <Grid container spacing={4}>
                        <Grid item sm={6} xs={12}>
                          <ReactFlagsSelect
                            id="locationSelect"
                            name="locationSelect"
                            selected={getValues("locationSelect.countryCode")}
                            onSelect={(countryCode) =>
                              onCountrySelect(countryCode, "locationSelect")
                            }
                            selectedSize={15}
                            showSelectedLabel
                            showOptionLabel
                            placeholder={"Select Location"}
                            searchable
                            frameBorder={"1px solid red"}
                            {...register("locationSelect")}
                          />
                          {renderErrorDesc("locationSelect", true, "country")}
                        </Grid>
                      </Grid>
                    </div>
                    <Grid>
                      <div
                        style={{
                          borderBottom: "1px dashed #ccc",
                          margin: "30px -30px",
                        }}
                        className="sectiondivider"
                      ></div>
                      <div className="form-group mx-auto ">
                        <Box
                          title="Please provide us with the loan requirements"
                          sx={{
                            background: {
                              xs: "#601A79",
                              sm: "#601A79",
                            },
                            pt: 1,
                            px: 2,
                            py: 1,
                            fontSize: { xs: 16, md: 20 },
                            color: "#fff",
                            borderRadius: "5px",
                            my: 2,
                          }}
                        >
                          Loan Information
                        </Box>
                        <Grid container spacing={4}>
                          <Grid item xs={12} md={6}>
                            <label htmlFor="loanAmount" className="strong mb-2">
                              Loan amount (USD)
                              <span className="text-danger">*</span>
                              <Tooltip title="Entered amount is in USD.">
                                <InfoOutlinedIcon
                                  sx={{
                                    fontSize: "15px",
                                    color: "#525252",
                                    position: "relative",
                                    top: "-2px",
                                    marginLeft: "5px",
                                  }}
                                ></InfoOutlinedIcon>
                              </Tooltip>
                            </label>
                            <RRCurrencyInput
                              locale={"en-US"}
                              currency={"USD"}
                              value={getValues("loanAmount")}
                              {...register("loanAmount")}
                              inputProps={{
                                id: "loanAmount",
                                name: "loanAmount",
                                placeholder: "Enter loan amount in USD",
                                className: "form-control required",
                              }}
                              onChange={onCurrencyChange}
                            />
                            {renderErrorDesc("loanAmount")}
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <label htmlFor="loanTenure" className="strong mb-2">
                              Loan Tenure
                              <span className="text-danger">*</span>
                              <Tooltip title="Please enter number of months">
                                <InfoOutlinedIcon
                                  sx={{
                                    fontSize: "15px",
                                    color: "#525252",
                                    position: "relative",
                                    top: "-2px",
                                    marginLeft: "5px",
                                  }}
                                ></InfoOutlinedIcon>
                              </Tooltip>
                            </label>
                            <RRTextField
                              fieldMargin={"none"}
                              formRegister={register}
                              fieldType="num"
                              fieldName={"loanTenure"}
                              fieldPlaceholder="If you need loan for 1 year, enter 12 months"
                              max={10}
                            />
                            {renderErrorDesc("loanTenure")}
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <label
                              htmlFor="loanPurpose"
                              className="strong mb-2"
                            >
                              Loan Purpose
                              <span className="text-danger">*</span>
                            </label>
                            <select
                              defaultValue=""
                              name="loanPurpose"
                              id="loanPurpose"
                              className="form-control required"
                              {...register("loanPurpose")}
                            >
                              <option disabled value="">
                                Select an option
                              </option>
                              {loanPurposeData.map((item) => {
                                return (
                                  <option
                                    key={item.value}
                                    value={item.value}
                                    label={item.label}
                                  >
                                    {item.value}
                                  </option>
                                );
                              })}
                            </select>
                            {renderErrorDesc("loanPurpose")}
                          </Grid>
                        </Grid>
                      </div>

                      <div
                        style={{
                          borderBottom: "1px dashed #ccc",
                          margin: "30px -30px",
                        }}
                        className="sectiondivider"
                      ></div>
                      <div className="form-group mx-auto ">
                        <Box
                          sx={{
                            background: {
                              xs: "#601A79",
                              sm: "#601A79",
                            },
                            pt: 1,
                            px: 2,
                            py: 1,
                            fontSize: { xs: 16, md: 20 },
                            color: "#fff",
                            borderRadius: "5px",
                            my: 2,
                          }}
                        >
                          Business Information
                        </Box>

                        <Grid container spacing={4}>
                          <Grid item xs={12} md={6}>
                            <label
                              htmlFor="businessRegCert"
                              className="semi-bold mb-2"
                            >
                              Upload Business Registration Certificate{" "}
                              <span className="text-danger">*</span>
                              <Tooltip
                                title="Please upload the copy of business registration /
                            certificate"
                              >
                                <InfoOutlinedIcon
                                  sx={{
                                    fontSize: "15px",
                                    color: "#525252",
                                    position: "relative",
                                    top: "-2px",
                                    marginLeft: "5px",
                                  }}
                                ></InfoOutlinedIcon>
                              </Tooltip>
                            </label>
                            <input
                              type="file"
                              name="businessRegCert"
                              accept=".pdf, .png, .jpeg, .jpg"
                              id="businessRegCert"
                              multiple
                              className="form-control required"
                              onChange={onChangeFile}
                              ref={businessRegCertRef}
                            />
                            {renderErrorDesc("businessRegCert")}
                            {fileList("businessRegCert", businessRegCertRef)}
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <label
                              htmlFor="businessRegNum"
                              className="strong mb-2"
                            >
                              Business Registration Number
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              name="businessRegNum"
                              id="businessRegNum"
                              className="form-control required"
                              maxLength={50}
                              placeholder="Enter your business registration number"
                              {...register("businessRegNum")}
                            />
                            {renderErrorDesc("businessRegNum")}
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <label
                              htmlFor="businessName"
                              className="strong mb-2"
                            >
                              Business Name
                              <span className="text-danger">*</span>
                              <Tooltip title="Please enter the business name">
                                <InfoOutlinedIcon
                                  sx={{
                                    fontSize: "15px",
                                    color: "#525252",
                                    position: "relative",
                                    top: "-2px",
                                    marginLeft: "5px",
                                  }}
                                ></InfoOutlinedIcon>
                              </Tooltip>
                            </label>
                            <input
                              type="text"
                              name="businessName"
                              id="businessName"
                              maxLength={100}
                              className="form-control required"
                              placeholder="Enter the business name"
                              {...register("businessName")}
                            />
                            {renderErrorDesc("businessName")}
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <label
                              htmlFor="businessDate"
                              className="strong mb-2"
                            >
                              Registration Date
                              <span className="text-danger">*</span>
                              <Tooltip title="Date on which the business is registered">
                                <InfoOutlinedIcon
                                  sx={{
                                    fontSize: "15px",
                                    color: "#525252",
                                    position: "relative",
                                    top: "-2px",
                                    marginLeft: "5px",
                                  }}
                                ></InfoOutlinedIcon>
                              </Tooltip>
                            </label>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                label="DD-MM-YYYY"
                                inputFormat="dd-MM-yyyy"
                                value={dateValue.businessDate}
                                maxDate={datehelper.getYesterdayDate()}
                                onChange={(value) =>
                                  onDateChange(value, "businessDate")
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    InputLabelProps={{
                                      style: { pointerEvents: "auto" },
                                    }}
                                    fullWidth
                                    name="businessDate"
                                  />
                                )}
                              />
                            </LocalizationProvider>
                            {renderErrorDesc("businessDate")}
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <label
                              htmlFor="businessCountry"
                              className="strong mb-2"
                            >
                              Country of Incorporation
                              <span className="text-danger">*</span>
                            </label>
                            <ReactFlagsSelect
                              name="businessCountry"
                              id="businessCountry"
                              selected={getValues(
                                "businessCountry.countryCode"
                              )}
                              onSelect={(countryCode) =>
                                onCountrySelect(countryCode, "businessCountry")
                              }
                              onBlur={({ field, value }) =>
                                setValue(field, value, { shouldValidate: true })
                              }
                              selectedSize={15}
                              showSelectedLabel
                              showOptionLabel
                              placeholder={
                                "Country where business is registered"
                              }
                              searchable
                              {...register("businessCountry")}
                            />
                            {renderErrorDesc(
                              "businessCountry",
                              true,
                              "country"
                            )}
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <label
                              htmlFor="industry-sec"
                              className="strong mb-2"
                            >
                              Industry or Sector
                              <span className="text-danger">*</span>
                            </label>
                            <select
                              name="industry"
                              id="industry-sec"
                              defaultValue=""
                              className="form-control required"
                              {...register("industry", {
                                onChange: (e) =>
                                  setBusinessInfo({
                                    ...business,
                                    industry: e.target.value,
                                  }),
                              })}
                            >
                              <option disabled value="">
                                Select an option
                              </option>
                              {industryData.map((item, id) => {
                                return (
                                  <option key={id} value={item}>
                                    {item}
                                  </option>
                                );
                              })}
                            </select>
                            {renderErrorDesc("industry")}
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <label
                              htmlFor="businessAddr"
                              className="strong mb-2"
                            >
                              Registered Business Address
                              <span className="text-danger">*</span>
                            </label>
                            <textarea
                              type="text"
                              name="businessAddr"
                              id="businessAddr"
                              className="form-control required"
                              maxLength={500}
                              style={{ height: "calc(2.65rem + 2px)" }}
                              placeholder="Enter the street address of your business"
                              {...register("businessAddr")}
                            />
                            {renderErrorDesc("businessAddr")}
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <label htmlFor="zipCode" className="strong mb-2">
                              Zip Code / Pin Code
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              name="businessZipCode"
                              id="businessZipCode"
                              maxLength={10}
                              className="form-control required"
                              placeholder="Business zip code or pin code"
                              {...register("businessZipCode")}
                            />
                            {renderErrorDesc("businessZipCode")}
                          </Grid>
                          <Grid item sm={6} xs={12}>
                            <label
                              htmlFor="businessAddrCountry"
                              className="strong mb-2"
                            >
                              Country
                              <span className="text-danger">*</span>
                            </label>
                            <ReactFlagsSelect
                              id="businessAddrCountry"
                              name="businessAddrCountry"
                              selected={getValues(
                                "businessAddrCountry.countryCode"
                              )}
                              onSelect={(countryCode) =>
                                onCountrySelect(
                                  countryCode,
                                  "businessAddrCountry"
                                )
                              }
                              selectedSize={15}
                              showSelectedLabel
                              showOptionLabel
                              placeholder={"Select Country"}
                              searchable
                              frameBorder={"1px solid red"}
                              {...register("businessAddrCountry")}
                            />
                            {renderErrorDesc(
                              "businessAddrCountry",
                              true,
                              "country"
                            )}
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <label
                              htmlFor="businessState"
                              className="strong mb-2"
                            >
                              State/Province
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              name="businessState"
                              id="businessState"
                              maxLength={255}
                              className="form-control required"
                              placeholder="Enter state/province"
                              {...register("businessState")}
                            />
                            {renderErrorDesc("businessState")}
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <label
                              htmlFor="businessEmail"
                              className="strong mb-2"
                            >
                              Business Email Address
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              name="businessEmail"
                              id="businessEmail"
                              maxLength={255}
                              className="form-control required"
                              placeholder="Enter business email address"
                              {...register("businessEmail")}
                            />
                            {renderErrorDesc("businessEmail")}
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <label
                              htmlFor="businessPhone"
                              className="strong mb-2"
                            >
                              Business Mobile Number
                              <span className="text-danger">*</span>
                            </label>
                            <RRPhoneInput
                              onChange={({ e }) =>
                                onPhoneNumberChange(e, "businessPhone")
                              }
                              country={
                                getValues("businessCountry.countryCode") ||
                                country.code
                              }
                              value={getValues("businessPhone")}
                              inputProps={{
                                name: "businessPhone",
                                required: true,
                                autoFocus: true,
                                placeholder: "Enter Mobile Number",
                              }}
                            />
                            {renderErrorDesc("businessPhone")}
                          </Grid>
                        </Grid>
                      </div>

                      <div
                        style={{
                          borderBottom: "1px dashed #ccc",
                          margin: "30px -30px",
                        }}
                        className="sectiondivider"
                      ></div>
                      <div className="form-group mx-auto ">
                        <Box
                          sx={{
                            background: {
                              xs: "#601A79",
                              sm: "#601A79",
                            },
                            pt: 1,
                            px: 2,
                            py: 1,
                            fontSize: { xs: 16, md: 20 },
                            color: "#fff",
                            borderRadius: "5px",
                            my: 2,
                          }}
                        >
                          Applicant Information
                        </Box>
                        <Grid container spacing={4}>
                          <Grid item xs={12} md={6}>
                            <label
                              htmlFor="applicantName"
                              className="semi-bold mb-2"
                            >
                              Applicant Name
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              name="applicantName"
                              id="applicantName"
                              maxLength={100}
                              className="form-control required"
                              placeholder="Enter applicant name"
                              {...register("applicantName")}
                            />
                            {renderErrorDesc("applicantName")}
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <label
                              htmlFor="Nationality"
                              className="strong mb-2"
                            >
                              Nationality
                              <span className="text-danger">*</span>
                            </label>
                            <ReactFlagsSelect
                              name="Nationality"
                              id="Nationality"
                              selected={getValues(
                                "applicantNationality.countryCode"
                              )}
                              onSelect={(countryCode) =>
                                onCountrySelect(
                                  countryCode,
                                  "applicantNationality"
                                )
                              }
                              selectedSize={15}
                              showSelectedLabel
                              showOptionLabel
                              placeholder={"Select an option"}
                              searchable
                            />
                            {renderErrorDesc(
                              "applicantNationality",
                              true,
                              "country"
                            )}
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <label
                              htmlFor="residentialStatus"
                              className="strong mb-2"
                            >
                              Residential status
                              <span className="text-danger">*</span>
                            </label>

                            <input
                              type="text"
                              name="residentialStatus"
                              id="residentialStatus"
                              className="form-control required"
                              placeholder="Residential status"
                              {...register("residentialStatus")}
                              maxLength={100}
                            />
                            {renderErrorDesc("residentialStatus")}
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <label
                              htmlFor="residentialAddr"
                              className="strong mb-2"
                            >
                              Residential address
                              <span className="text-danger">*</span>
                            </label>
                            <textarea
                              type="text"
                              name="residentialAddr"
                              id="residentialAddr"
                              maxLength={500}
                              className="form-control required"
                              style={{ height: "calc(2.65rem + 2px)" }}
                              placeholder="Residential Address"
                              {...register("residentialAddr")}
                            />
                            {renderErrorDesc("residentialAddr")}
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <label
                              htmlFor="applicantZip"
                              className="strong mb-2"
                            >
                              Zip Code or Pin Code
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              name="applicantZip"
                              id="applicantZip"
                              className="form-control required"
                              maxLength={10}
                              placeholder="Zip Code/Pin Code"
                              {...register("applicantZip")}
                            />
                            {renderErrorDesc("applicantZip")}
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <label
                              htmlFor="applicantEmail"
                              className="strong mb-2"
                            >
                              Applicant Email Address
                              <span className="text-danger">*</span>
                            </label>
                            <textarea
                              type="text"
                              name="applicantEmail"
                              id="applicantEmail"
                              className="form-control required"
                              maxLength={255}
                              style={{ height: "calc(2.65rem + 2px)" }}
                              placeholder="Enter applicant email address"
                              {...register("applicantEmail")}
                            />
                            {renderErrorDesc("applicantEmail")}
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <label
                              htmlFor="applicantPhone"
                              className="strong mb-2"
                            >
                              Applicant Mobile Number
                              <span className="text-danger">*</span>
                              <Tooltip title="Please enter country code along with number">
                                <InfoOutlinedIcon
                                  sx={{
                                    fontSize: "15px",
                                    color: "#525252",
                                    position: "relative",
                                    top: "-2px",
                                    marginLeft: "5px",
                                  }}
                                ></InfoOutlinedIcon>
                              </Tooltip>
                            </label>

                            <RRPhoneInput
                              onChange={({ e }) =>
                                onPhoneNumberChange(e, "applicantPhone")
                              }
                              country={
                                getValues("applicantNationality.countryCode") ||
                                country.code
                              }
                              value={getValues("applicantPhone")}
                              inputProps={{
                                name: "applicantPhone",
                                required: true,
                                autoFocus: true,
                                placeholder: "Enter Mobile Number",
                              }}
                            />
                            {renderErrorDesc("applicantPhone")}
                          </Grid>
                        </Grid>
                      </div>

                      <div
                        style={{
                          borderBottom: "1px dashed #ccc",
                          margin: "30px -30px",
                        }}
                        className="sectiondivider"
                      ></div>
                      <div className="form-group mx-auto ">
                        <Box
                          title="Please provide us with the following documents to
                      complete the application requirements"
                          sx={{
                            background: {
                              xs: "#601A79",
                              sm: "#601A79",
                            },
                            pt: 1,
                            px: 2,
                            py: 1,
                            fontSize: { xs: 16, md: 20 },
                            color: "#fff",
                            borderRadius: "5px",
                            my: 2,
                          }}
                        >
                          Upload Documents
                        </Box>
                        <Grid container spacing={4}>
                          <Grid item xs={12} md={6}>
                            <label
                              htmlFor="bankStatements"
                              className="semi-bold"
                            >
                              Upload Bank Statements
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="file"
                              name="bankStatements"
                              accept=".pdf"
                              id="bankStatements"
                              className="form-control required"
                              placeholder="SG$"
                              onChange={onChangeFile}
                              ref={bankStatementsRef}
                              multiple
                            />
                            {renderErrorDesc("bankStatements")}
                            {fileList("bankStatements", bankStatementsRef)}
                            <ul>
                              <li
                                className="small text-left"
                                style={{ marginTop: "15px" }}
                              >
                                PDFs (not scanned copies) of company's operating
                                bank current account(s) statements for the past
                                6 months. Ex: If today is 2 July 22, then please
                                upload bank statements from Jan 22 to Jun 22
                                (both months inclusive).
                              </li>
                              <li
                                className="small text-left"
                                style={{ marginTop: "15px" }}
                              >
                                If your company is multi-banked, then please
                                upload 6 months bank statements for each bank
                                account.
                              </li>
                            </ul>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <label
                              htmlFor="financeStatements"
                              className="semi-bold"
                            >
                              Upload Financial Statements
                              {renderErrorDesc("financeStatements") ? (
                                <span className="text-danger">*</span>
                              ) : null}
                            </label>

                            <input
                              type="file"
                              name="financeStatements"
                              accept=".pdf, .png, .jpeg, .jpg"
                              id="financeStatements"
                              className="form-control required"
                              onChange={onChangeFile}
                              ref={financeStatementsRef}
                              multiple
                            />
                            {submitCount > 0
                              ? renderErrorDesc("financeStatements")
                              : null}
                            {fileList(
                              "financeStatements",
                              financeStatementsRef
                            )}
                            <ul>
                              <li
                                className="small text-left"
                                style={{ marginTop: "15px" }}
                              >
                                Upload recent 2 years audited financial
                                statements (scanned audited financials or pdf).
                              </li>
                              <li
                                className="small text-left"
                                style={{ marginTop: "15px" }}
                              >
                                If Loan amount is more than US$ 200,000 ,It's
                                required to upload Financial Statements.
                              </li>
                            </ul>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <label
                              htmlFor="gstStatements"
                              className="semi-bold"
                            >
                              Upload GST Statements
                            </label>

                            <input
                              type="file"
                              name="gstStatements"
                              accept=".pdf"
                              id="gstStatements"
                              className="form-control required"
                              onChange={onChangeFile}
                              ref={gstStatementsRef}
                              multiple
                            />
                            {renderErrorDesc("gstStatements")}
                            {fileList("gstStatements", gstStatementsRef)}
                            <p
                              className="small text-left"
                              style={{ marginTop: "15px" }}
                            >
                              PDFs (not scanned copies) of the last 12 months /
                              4 quaters (GST) Statements (can be downloaded from
                              IRAS website)
                            </p>
                          </Grid>
                          {isRRF ? (
                            <Grid item xs={12} md={6}>
                              <label
                                htmlFor="bankStatements"
                                className="semi-bold"
                              >
                                Upload Revenue Details
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="file"
                                name="revenueFinancing"
                                accept=".xlsx"
                                id="revenueFinancing"
                                className="form-control required"
                                onChange={onChangeFile}
                                ref={revenueFinancingRef}
                              />
                              {renderErrorDesc("revenueFinancing")}
                              {fileList(
                                "revenueFinancing",
                                revenueFinancingRef
                              )}
                              <ul>
                                <li
                                  className="small text-left"
                                  style={{ marginTop: "15px" }}
                                >
                                  You can download the sample template:{" "}
                                  <a href={ExcelFile}>Click Here</a>.
                                </li>
                                <li
                                  className="small text-left"
                                  style={{ marginTop: "15px" }}
                                >
                                  The instructions are clearly mentioned in the
                                  document on how to complete/fill
                                </li>
                                <li
                                  className="small text-left"
                                  style={{ marginTop: "15px" }}
                                >
                                  Do not change any headers and request you to
                                  fill all information requested
                                </li>
                                <li
                                  className="small text-left"
                                  style={{ marginTop: "15px" }}
                                >
                                  Once you fill the revenue information, please
                                  upload the file using Choose File button
                                </li>
                              </ul>
                            </Grid>
                          ) : null}
                        </Grid>
                      </div>

                      <div className="form-group mx-auto ">
                        <Box
                          title="Please provide us with the following documents to
                      complete the application requirements"
                          sx={{
                            background: {
                              xs: "#601A79",
                              sm: "#601A79",
                            },
                            pt: 1,
                            px: 2,
                            py: 1,
                            fontSize: { xs: 16, md: 20 },
                            color: "#fff",
                            borderRadius: "5px",
                            my: 2,
                          }}
                        >
                          Director1 Information
                        </Box>

                        <Grid container spacing={4}>
                          <Grid item xs={12} md={6}>
                            <label
                              htmlFor="directorName1"
                              className="strong mb-2"
                            >
                              Director Name
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              name="directorName1"
                              id="directorName1"
                              maxLength={100}
                              className="form-control required"
                              placeholder="Enter the director name"
                              {...register("directorName1")}
                            />
                            {renderErrorDesc("directorName1")}
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <label
                              htmlFor="personalIdentificationNumber1"
                              className="strong mb-2"
                            >
                              Personal Identification Number
                              <span className="text-danger">*</span>
                            </label>
                            <RRTextField
                              fieldMargin={"none"}
                              formRegister={register}
                              fieldName={"personalIdentificationNumber1"}
                              fieldPlaceholder="Enter the personal identification number"
                              max={20}
                            />
                            {renderErrorDesc("personalIdentificationNumber1")}
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <label
                              htmlFor="shareholding1"
                              className="strong mb-2"
                            >
                              Shareholding (%)
                              <span className="text-danger">*</span>
                            </label>
                            <RRTextField
                              fieldMargin={"none"}
                              formRegister={register}
                              fieldType="num"
                              fieldName={"shareholding1"}
                              fieldPlaceholder="Enter shareholding %"
                              max={10}
                            />
                            {renderErrorDesc("shareholding1")}
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <label
                              htmlFor="directorAddress1"
                              className="strong mb-2"
                            >
                              Residential address
                              <span className="text-danger">*</span>
                            </label>
                            <textarea
                              type="text"
                              name="directorAddress1"
                              id="directorAddress1"
                              maxLength={500}
                              className="form-control required"
                              style={{ height: "calc(2.65rem + 2px)" }}
                              placeholder="Residential Address"
                              {...register("directorAddress1")}
                            />
                            {renderErrorDesc("directorAddress1")}
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <label
                              htmlFor="directorZipCode1"
                              className="strong mb-2"
                            >
                              Zip Code / Pin Code
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              name="directorZipCode1"
                              id="directorZipCode1"
                              maxLength={10}
                              className="form-control required"
                              placeholder="Business zip code or pin code"
                              {...register("directorZipCode1")}
                            />
                            {renderErrorDesc("directorZipCode1")}
                          </Grid>
                          <Grid item sm={6} xs={12}>
                            <label
                              htmlFor="directorCountry1"
                              className="strong mb-2"
                            >
                              Country
                              <span className="text-danger">*</span>
                            </label>
                            <ReactFlagsSelect
                              id="directorCountry1"
                              name="directorCountry1"
                              selected={getValues(
                                "directorCountry1.countryCode"
                              )}
                              onSelect={(countryCode) =>
                                onCountrySelect(countryCode, "directorCountry1")
                              }
                              selectedSize={15}
                              showSelectedLabel
                              showOptionLabel
                              placeholder={"Select Country"}
                              searchable
                              frameBorder={"1px solid red"}
                              {...register("directorCountry1")}
                            />
                            {renderErrorDesc(
                              "directorCountry1",
                              true,
                              "country"
                            )}
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <label
                              htmlFor="directorPhone1"
                              className="strong mb-2"
                            >
                              Phone Number
                              <span className="text-danger">*</span>
                            </label>
                            <RRPhoneInput
                              onChange={({ e }) =>
                                onPhoneNumberChange(e, "directorPhone1")
                              }
                              country={
                                getValues("directorCountry1.countryCode") ||
                                country.code
                              }
                              value={getValues("directorPhone1")}
                              inputProps={{
                                name: "directorPhone1",
                                required: true,
                                autoFocus: true,
                                placeholder: "Enter Phone Number",
                              }}
                            />
                            {renderErrorDesc("directorPhone1")}
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <label
                              htmlFor="directorEmail1"
                              className="strong mb-2"
                            >
                              Email Address
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              name="directorEmail1"
                              id="directorEmail1"
                              maxLength={255}
                              className="form-control required"
                              placeholder="Enter email address"
                              {...register("directorEmail1")}
                            />
                            {renderErrorDesc("directorEmail1")}
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <label htmlFor="passport1" className="semi-bold">
                              Upload Passport (image clicked by phone or
                              scanned)
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="file"
                              name="passport1"
                              accept=".pdf, .png, .jpeg, .jpg"
                              id="passport1"
                              className="form-control required"
                              placeholder="SG$"
                              onChange={onChangeFile}
                              ref={passport1Ref}
                              multiple
                            />
                            {renderErrorDesc("passport1")}
                            {fileList("passport1", passport1Ref)}
                            <p
                              className="small text-left"
                              style={{ marginTop: "15px" }}
                            >
                              The uploaded file (PDF or JPEG or PNG) should
                              clearly show (photograph, name, passport number,
                              and date of birth)
                            </p>
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <label
                              htmlFor="utilityBills1"
                              className="semi-bold"
                            >
                              Upload Utility Bill (water, electricity, gas,
                              credit card, phone/mobile, rent agreement)
                              <span className="text-danger">*</span>
                            </label>

                            <input
                              type="file"
                              name="utilityBills1"
                              accept=".pdf, .png, .jpeg, .jpg"
                              id="utilityBills1"
                              className="form-control required"
                              onChange={onChangeFile}
                              multiple
                              ref={utilityBills1Ref}
                            />
                            {renderErrorDesc("utilityBills1")}
                            {fileList("utilityBills1", utilityBills1Ref)}
                            <p
                              className="small text-left"
                              style={{ marginTop: "15px" }}
                            >
                              The uploaded file (PDF or JPEG or PNG) should
                              clearly show the address and name. The bill cannot
                              be older than 3 months from the current date
                            </p>
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <label htmlFor="passport1" className="semi-bold">
                              Video Call Verification
                              <span className="text-danger">*</span>
                            </label>
                            <p className="small text-left">
                              Please suggest a suitable time when we can do a
                              short video call. Please have your passport and
                              the utility bill available for verification.
                              Please enter date and time{" "}
                              <strong>(Singapore time)</strong>
                            </p>
                            <p className="small text-left">
                              Credilinq will perform a quick check on:
                            </p>
                            <ul>
                              <li className="small text-left">
                                Liveliness ( Attendant should turn on camera in
                                the duration of the call)
                              </li>
                              <li className="small text-left">
                                Identity Validation (Attendant should show the
                                uploaded passport in the camera while on call)
                              </li>
                              <li className="small text-left">
                                Address Validation (Attendant should show the
                                uploaded proof in the camera while on call)
                              </li>
                            </ul>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <label
                              htmlFor="videoCallDate1"
                              className="strong mb-2"
                            >
                              Video Call Date
                              <span className="text-danger">*</span>
                            </label>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                label="Date"
                                inputFormat="dd-MM-yyyy"
                                value={dateValue.videoCallDate1}
                                onChange={(value) =>
                                  onDateChange(value, "videoCallDate1")
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    InputLabelProps={{
                                      style: { pointerEvents: "auto" },
                                    }}
                                    fullWidth
                                    name="videoCallDate1"
                                  />
                                )}
                              />
                            </LocalizationProvider>
                            {renderErrorDesc("videoCallDate1")}
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <label
                              htmlFor="videoCallTime1"
                              className="strong mb-2"
                            >
                              Video Call Time
                              <span className="text-danger">*</span>
                            </label>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <TimePicker
                                label="Time (SG)"
                                value={timeValue.videoCallTime1}
                                onChange={(value) =>
                                  onTimeChange(value, "videoCallTime1")
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    fullWidth
                                    name="videoCallTime1"
                                  />
                                )}
                              />
                            </LocalizationProvider>
                            {renderErrorDesc("videoCallTime1")}
                          </Grid>
                        </Grid>
                      </div>

                      <div className="form-group mx-auto ">
                        <Grid
                          container
                          sx={{
                            background: {
                              xs: "#601A79",
                              sm: "#601A79",
                            },
                            pt: 1,
                            px: 2,
                            py: 1,
                            fontSize: { xs: 16, md: 20 },
                            color: "#fff",
                            borderRadius: "5px",
                            my: 2,
                          }}
                        >
                          <Grid display="flex" xs={6} md={6}>
                            Director2 Information
                          </Grid>
                          <Grid
                            display="flex"
                            justifyContent="end"
                            xs={6}
                            md={6}
                          >
                            <FormGroup sx={{ p: 0, m: 0 }}>
                              <FormControlLabel
                                sx={{
                                  p: 0,
                                  m: 0,
                                  display: "flex",
                                  color: "#ffffff",
                                  fontSize: { xs: 16, md: 20 },
                                }}
                                control={
                                  <Checkbox
                                    sx={{
                                      p: 0,
                                      my: 0,
                                      mx: 1,
                                      color: "#ffffff",
                                      "&.Mui-checked": {
                                        color: "#ffffff",
                                      },
                                    }}
                                    name="active"
                                    checked={secondDirector}
                                    onChange={(e) => {
                                      setSecondDirector(e.target.checked);
                                    }}
                                  />
                                }
                                label="Not Applicable"
                                labelPlacement="start"
                              />
                            </FormGroup>
                            <Tooltip title="You can mark this as not applicable if your company has only 1 director">
                              <InfoOutlinedIcon
                                sx={{
                                  fontSize: "15px",
                                  color: "#ffffff",
                                  position: "relative",
                                  top: "5px",
                                }}
                              ></InfoOutlinedIcon>
                            </Tooltip>
                          </Grid>
                        </Grid>
                        {!secondDirector ? (
                          <Grid container spacing={4}>
                            <Grid item xs={12} md={6}>
                              <label
                                htmlFor="directorName2"
                                className="strong mb-2"
                              >
                                Director Name
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                name="directorName2"
                                id="directorName2"
                                maxLength={100}
                                className="form-control required"
                                placeholder="Enter the director name"
                                {...register("directorName2")}
                              />
                              {renderErrorDesc("directorName2")}
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <label
                                htmlFor="personalIdentificationNumber2"
                                className="strong mb-2"
                              >
                                Personal Identification Number
                                <span className="text-danger">*</span>
                              </label>
                              <RRTextField
                                fieldMargin={"none"}
                                formRegister={register}
                                fieldName={"personalIdentificationNumber2"}
                                fieldPlaceholder="Enter the personal identification number"
                                max={20}
                              />
                              {renderErrorDesc("personalIdentificationNumber2")}
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <label
                                htmlFor="shareholding2"
                                className="strong mb-2"
                              >
                                Shareholding (%)
                                <span className="text-danger">*</span>
                              </label>
                              <RRTextField
                                fieldMargin={"none"}
                                formRegister={register}
                                fieldType="num"
                                fieldName={"shareholding2"}
                                fieldPlaceholder="Enter shareholding %"
                                max={10}
                              />
                              {renderErrorDesc("shareholding2")}
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <label
                                htmlFor="directorAddress2"
                                className="strong mb-2"
                              >
                                Residential address
                                <span className="text-danger">*</span>
                              </label>
                              <textarea
                                type="text"
                                name="directorAddress2"
                                id="directorAddress2"
                                maxLength={500}
                                className="form-control required"
                                style={{ height: "calc(2.65rem + 2px)" }}
                                placeholder="Residential Address"
                                {...register("directorAddress2")}
                              />
                              {renderErrorDesc("directorAddress2")}
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <label
                                htmlFor="directorZipCode2"
                                className="strong mb-2"
                              >
                                Zip Code / Pin Code
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                name="directorZipCode2"
                                id="directorZipCode2"
                                maxLength={10}
                                className="form-control required"
                                placeholder="Business zip code or pin code"
                                {...register("directorZipCode2")}
                              />
                              {renderErrorDesc("directorZipCode2")}
                            </Grid>
                            <Grid item sm={6} xs={12}>
                              <label
                                htmlFor="directorCountry2"
                                className="strong mb-2"
                              >
                                Country
                                <span className="text-danger">*</span>
                              </label>
                              <ReactFlagsSelect
                                id="directorCountry2"
                                name="directorCountry2"
                                selected={getValues(
                                  "directorCountry2.countryCode"
                                )}
                                onSelect={(countryCode) =>
                                  onCountrySelect(
                                    countryCode,
                                    "directorCountry2"
                                  )
                                }
                                selectedSize={15}
                                showSelectedLabel
                                showOptionLabel
                                placeholder={"Select Country"}
                                searchable
                                frameBorder={"1px solid red"}
                                {...register("directorCountry2")}
                              />
                              {renderErrorDesc(
                                "directorCountry2",
                                true,
                                "country"
                              )}
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <label
                                htmlFor="directorPhone2"
                                className="strong mb-2"
                              >
                                Phone Number
                                <span className="text-danger">*</span>
                              </label>
                              <RRPhoneInput
                                onChange={({ e }) =>
                                  onPhoneNumberChange(e, "directorPhone2")
                                }
                                country={
                                  getValues("directorCountry2.countryCode") ||
                                  country.code
                                }
                                value={getValues("directorPhone2")}
                                inputProps={{
                                  name: "directorPhone2",
                                  required: true,
                                  autoFocus: true,
                                  placeholder: "Enter Phone Number",
                                }}
                              />
                              {renderErrorDesc("directorPhone2")}
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <label
                                htmlFor="directorEmail2"
                                className="strong mb-2"
                              >
                                Email Address
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                name="directorEmail2"
                                id="directorEmail2"
                                maxLength={255}
                                className="form-control required"
                                placeholder="Enter email address"
                                {...register("directorEmail2")}
                              />
                              {renderErrorDesc("directorEmail2")}
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <label htmlFor="passport2" className="semi-bold">
                                Upload Passport (image clicked by phone or
                                scanned)
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="file"
                                name="passport2"
                                accept=".pdf, .png, .jpeg, .jpg"
                                id="passport2"
                                className="form-control required"
                                placeholder="SG$"
                                onChange={onChangeFile}
                                multiple
                                ref={passport2Ref}
                              />
                              {renderErrorDesc("passport2")}
                              {fileList("passport2", passport2Ref)}
                              <p
                                className="small text-left"
                                style={{ marginTop: "15px" }}
                              >
                                The uploaded file (PDF or JPEG or PNG) should
                                clearly show (photograph, name, passport number,
                                and date of birth)
                              </p>
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <label
                                htmlFor="utilityBills2"
                                className="semi-bold"
                              >
                                Upload Utility Bill (water, electricity, gas,
                                credit card, phone/mobile, rent agreement)
                                <span className="text-danger">*</span>
                              </label>

                              <input
                                type="file"
                                name="utilityBills2"
                                accept=".pdf, .png, .jpeg, .jpg"
                                id="utilityBills2"
                                className="form-control required"
                                onChange={onChangeFile}
                                ref={utilityBills2Ref}
                                multiple
                              />
                              {renderErrorDesc("utilityBills2")}
                              {fileList("utilityBills2", utilityBills2Ref)}
                              <p
                                className="small text-left"
                                style={{ marginTop: "15px" }}
                              >
                                The uploaded file (PDF or JPEG or PNG) should
                                clearly show the address and name. The bill
                                cannot be older than 3 months from the current
                                date
                              </p>
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <label htmlFor="passport1" className="semi-bold">
                                Video Call Verification
                                <span className="text-danger">*</span>
                              </label>
                              <p className="small text-left">
                                Please suggest a suitable time when we can do a
                                short video call. Please have your passport and
                                the utility bill available for verification.
                                Please enter date and time{" "}
                                <strong>(Singapore time)</strong>
                              </p>
                              <p className="small text-left">
                                Credilinq will perform a quick check on:
                              </p>
                              <ul>
                                <li className="small text-left">
                                  Liveliness ( Attendant should turn on camera
                                  in the duration of the call)
                                </li>
                                <li className="small text-left">
                                  Identity Validation (Attendant should show the
                                  uploaded passport in the camera while on call)
                                </li>
                                <li className="small text-left">
                                  Address Validation (Attendant should show the
                                  uploaded proof in the camera while on call)
                                </li>
                              </ul>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <label
                                htmlFor="videoCallDate2"
                                className="strong mb-2"
                              >
                                Video Call Date
                                <span className="text-danger">*</span>
                              </label>
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                              >
                                <DatePicker
                                  label="Date"
                                  inputFormat="dd-MM-yyyy"
                                  value={dateValue.videoCallDate2}
                                  onChange={(value) =>
                                    onDateChange(value, "videoCallDate2")
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      InputLabelProps={{
                                        style: { pointerEvents: "auto" },
                                      }}
                                      fullWidth
                                      name="videoCallDate2"
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                              {renderErrorDesc("videoCallDate2")}
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <label
                                htmlFor="videoCallTime2"
                                className="strong mb-2"
                              >
                                Video Call Time
                                <span className="text-danger">*</span>
                              </label>
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                              >
                                <TimePicker
                                  label="Time (SG)"
                                  value={timeValue.videoCallTime2}
                                  onChange={(value) =>
                                    onTimeChange(value, "videoCallTime2")
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      fullWidth
                                      name="videoCallTime2"
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                              {renderErrorDesc("videoCallTime2")}
                            </Grid>
                          </Grid>
                        ) : null}
                      </div>

                      <div
                        style={{
                          borderBottom: "1px dashed #ccc",
                          margin: "30px -30px",
                        }}
                        className="sectiondivider"
                      ></div>
                      <div className="form-group mx-auto   ">
                        <Box
                          sx={{
                            background: {
                              xs: "#601A79",
                              sm: "#601A79",
                            },
                            pt: 1,
                            px: 2,
                            py: 1,
                            fontSize: { xs: 16, md: 20 },
                            color: "#fff",
                            borderRadius: "5px",
                            my: 2,
                          }}
                        >
                          Authorization & Consent
                        </Box>
                        <Grid container spacing={2}>
                          <Grid item xs={9} mx={3}>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="checkTerms"
                              name="checkTerms"
                              {...register("checkTerms")}
                              style={{
                                outline: errors?.checkTerms
                                  ? "1px solid red"
                                  : "",
                              }}
                            />
                            <label
                              className="form-check-label small"
                              htmlFor="checkTerms"
                            >
                              I acknowledge that I have read and understood the
                              Standard{" "}
                              <a
                                href={TermsConditions}
                                target={"_blank"}
                                rel="noopener noreferrer"
                              >
                                Terms & Conditions
                              </a>
                            </label>
                          </Grid>

                          <Grid item xs={9} mx={3}>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="check-dataprotection"
                              name="checkDataProtection"
                              {...register("checkDataProtection")}
                              style={{
                                outline: errors?.checkDataProtection
                                  ? "1px solid red"
                                  : "",
                              }}
                            />
                            <label
                              className="form-check-label small"
                              htmlFor="check-dataprotection"
                            >
                              I acknowledge that I have read and understood the
                              above{" "}
                              <a
                                href={DataProtection}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Data Protection Notice,
                              </a>{" "}
                              and consent to the collection, use and disclosure
                              of my personal data by CrediLinq.Ai Pte Ltd and
                              AsiaLinq Investments Pte Ltd for the purpose set
                              out in Notice
                            </label>
                          </Grid>

                          <Grid item xs={9} mx={3}>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="check-all"
                              {...register("checkAll")}
                              checked={
                                getValues("checkTerms") &&
                                getValues("checkDataProtection")
                              }
                              onClick={(e) => {
                                setValue("checkTerms", e.target.checked, {
                                  shouldValidate: true,
                                  shouldDirty: true,
                                });
                                setValue(
                                  "checkDataProtection",
                                  e.target.checked,
                                  {
                                    shouldValidate: true,
                                    shouldDirty: true,
                                  }
                                );
                              }}
                            />
                            <label
                              className="form-check-label small"
                              htmlFor="check-all"
                            >
                              I agree that all above information is correct and
                              can be used for underwriting
                            </label>
                          </Grid>
                        </Grid>
                      </div>
                      <div id="bottom-wizard">
                        <input
                          type="submit"
                          name="submit"
                          className="btn_1"
                          value="Submit"
                          sx={{
                            background: "#000054 !important",
                            "&:hover": {
                              background: "#601a79 !important",
                            },
                          }}
                        />
                      </div>
                    </Grid>
                  </div>
                </Box>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoanApp;
