import React, { useEffect, useState } from 'react';
import { Box, Link, Stack, Typography } from '@mui/material';
import { Header } from '../common/components/Header';
import Footer from '../common/components/Footer';
import { useParams } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';

const Thankyou = () => {
    const { applicantName, phone, id } = useParams();

    return (
        <Stack sx={{ minHeight: '100vh' }} justifyContent="space-between">
            <Header>Form Submitted</Header>
            <Stack sx={{ flex: 1 }}>
                <Box
                    sx={{ display: 'flex', justifyContent: 'center', px: 3, mt: { lg: 7, xs: 5 } }}
                >
                    <Typography
                        variant="h3"
                        sx={{
                            textAlign: 'center',
                            display: { sm: 'block' },
                            fontSize: { lg: 40, sm: 32.5, xs: 26 },
                            color: 'rgba(0, 0, 0, 0.85)',
                            fontFamily: 'Roboto, sans-serif',
                            fontStyle: 'normal',
                        }}
                    >
                        Thank you {applicantName}!! your application is successfully submitted.
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', px: 4, py: 2 }}>
                    <Box
                        sx={{
                            height: '1px',
                            width: { lg: 700, sm: 600, xs: 500 },
                            background:
                                'linear-gradient(90deg, #FFFFFF 0%, rgba(0, 0, 0, 0) 49.48%, #FFFFFF 100%), linear-gradient(265.65deg, rgba(253, 92, 72, 0.9) 14.69%, rgba(255, 3, 50, 0.9) 23.35%, rgba(234, 1, 129, 0.9) 31.43%, rgba(155, 0, 120, 0.9) 44.22%, rgba(77, 0, 102, 0.9) 62.19%, rgba(0, 0, 84, 0.9) 76.51%, rgba(0, 0, 84, 0.9) 87.07%)',
                        }}
                    ></Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', px: 4, mt: 1 }}>
                    <Typography
                        variant="h5"
                        sx={{
                            textAlign: 'center',
                            fontWeight: '400',
                            display: { sm: 'block' },
                            fontSize: { sm: 20, xs: 16 },
                            color: 'rgba(0, 0, 0, 0.85)',
                            fontStyle: 'normal',
                        }}
                    >
                        You will be contacted on your registered mobile number and email address if
                        our analysts will be looking for some clarification.
                    </Typography>
                </Box>
                <Box
                    sx={{ display: 'flex', justifyContent: 'center', px: 6, mt: { xs: 2, md: 1 } }}
                >
                    <Typography
                        variant="body1"
                        sx={{
                            textAlign: 'center',
                            fontWeight: '400',
                            fontSize: { lg: 18, sm: 16, xs: 14 },
                            color: 'rgba(0, 0, 0, 0.6)',
                            fontStyle: 'normal',
                            letterSpacing: '0.15px',
                            mb: { xs: 5, md: 0 },
                        }}
                    >
                        Thank you for trusting us for your financial needs.
                    </Typography>
                </Box>
                <Stack
                    flexDirection={{ xs: 'column', sm: 'column' }}
                    sx={{
                        flex: 1,
                        mb: 2,
                        maxWidth: '1386px',
                        width: '100%',
                        margin: '0 auto',
                        gap: 6,
                    }}
                    justifyContent="space-between"
                    alignItems="center"
                    gap={{ xs: 10 }}
                >
                    <Box
                        sx={{
                            flex: 2,
                            textAlign: 'center',
                            color: 'primary.main',
                            position: 'relative',
                            maxWidth: '100%',
                            width: 600,
                            minHeight: '190px',
                            paddingTop: '80px',
                            paddingBottom: '80px',
                            marginTop: '50px',
                            overflow: 'hidden',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Box
                            sx={{
                                position: 'absolute',
                                borderRadius: '50%',
                                top: 0,
                                left: 40,
                                zIndex: -1,
                                width: 350,
                                height: 350,
                                background: 'rgba(255, 68, 135, 0.04)',
                            }}
                        />
                        <Box
                            sx={{
                                position: 'absolute',
                                borderRadius: '50%',
                                top: 0,
                                right: 40,
                                zIndex: -2,
                                width: 320,
                                height: 320,
                                background: 'rgba(133, 133, 218, 0.04)',
                            }}
                        />
                        <Card sx={{ p: 2, borderRadius: 4 }}>
                            <Stack
                                alignItems="start"
                                divider={<Divider orientation="horizontal" flexItem />}
                                spacing={2}
                            >
                                <Stack direction="row">
                                    <Typography
                                        sx={{
                                            fontSize: { xs: 16, sm: 18 },
                                            fontWeight: 600,
                                            mr: 1,
                                            color: '#000000',
                                        }}
                                    >
                                        Application Number :
                                    </Typography>
                                    <Typography
                                        sx={{ fontSize: { xs: 16, sm: 18 }, color: '#000000' }}
                                    >
                                        {id && id.length > 0 && id !== 'null' && id !== 'undefined'
                                            ? id
                                            : 'XXXXXXXXXXXX'}
                                    </Typography>
                                </Stack>
                                <Stack direction="row">
                                    <Typography
                                        sx={{
                                            fontSize: { xs: 16, sm: 18 },
                                            fontWeight: 600,
                                            mr: 1,
                                            color: '#000000',
                                        }}
                                    >
                                        Applicant Name :
                                    </Typography>
                                    <Typography
                                        sx={{ fontSize: { xs: 16, sm: 18 }, color: '#000000' }}
                                    >
                                        {applicantName && applicantName.length > 0
                                            ? applicantName
                                            : 'N/A'}
                                    </Typography>
                                </Stack>
                                <Stack direction="row">
                                    <Typography
                                        sx={{
                                            fontSize: { xs: 16, sm: 18 },
                                            fontWeight: 600,
                                            mr: 1,
                                            color: '#000000',
                                        }}
                                    >
                                        Applicant Phone :
                                    </Typography>
                                    <Typography
                                        sx={{ fontSize: { xs: 16, sm: 18 }, color: '#000000' }}
                                    >
                                        {phone && phone.length > 0 ? phone : 'N/A'}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Card>
                    </Box>
                    <Box
                        sx={{
                            flex: 1.7,
                            px: '15px',
                            pb: 5,
                            maxWidth: 650,
                            margin: 'auto',
                            width: '100%',
                        }}
                    >
                        <Stack gap={1.75}>
                            You can reach us by emailing at loans@credilinq.ai and use above
                            information as reference
                        </Stack>
                    </Box>
                </Stack>
            </Stack>
            <Footer />
        </Stack>
    );
};

export default Thankyou;
