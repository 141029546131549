import { TextField } from '@mui/material';
import * as _ from 'lodash';



export default function RRTextField({ fieldName, defaultVal, fieldLabel, fieldType, fieldValue, fieldPlaceholder, isRequired,
    errorMessage, onChange, max, fieldMargin, isMultiLine, rows, maxRows, isDisabled, helperText, formRegister, ...rest }) {
    const dataType = _.isNil(fieldType) ? 'text' : fieldType;
    const placeholder = _.isNil(fieldPlaceholder) ? '' : fieldPlaceholder;
    const isFieldRequired = _.isNil(isRequired) ? true : isRequired;
    const maxLength = _.isNil(max) ? null : max;
    const textFieldMargin = _.isNil(fieldMargin) ? 'normal' : 'none';

    const isValidNumber = (numberValue) => {
        const reg = new RegExp('^[0-9]+$');
        return reg.test(numberValue);
    }

    const formatPhoneNumber = (phone) => {
        if (!_.isNil(phone)) {
            var phoneNumber = phone.replace(/\D/g, '');
            return `(${phoneNumber.substr(0, 3)}) ${phoneNumber.substr(3, 3)}-${phoneNumber.substr(6, 4)}`;
        } else {
            return null;
        }
    }

    const onFieldChange = (e) => {
        const { name, value } = e.target;
        switch (dataType) {
            case 'phone':
                var phoneValue = _.cloneDeep(value);
                phoneValue = _.replace(_.replace(_.replace(_.replace(phoneValue, '(', ''), ')', ''), ' ', ''), '-', '');
                if (phoneValue.length > 6) {
                    phoneValue = formatPhoneNumber(phoneValue);
                }
                fieldValue = phoneValue;
                onChange({ field: name, value: phoneValue });
                break;
            case 'num':
                var numbersOnly = value.match(/\d+/g);
                var numberValue = '';
                if (_.size(numbersOnly) > 0) {
                    numberValue = _.join(numbersOnly, '');
                }

                onChange({ field: name, value: numberValue });
                break;
            case 'decimal':
                var decimalOnly = value.match(/^\d+\.?\d*$/);
                var decimalValue = '';
                if (_.size(decimalOnly) > 0) {
                    decimalValue = _.join(decimalOnly, '');
                }

                onChange({ field: name, value: decimalValue });
                break;
            default:
                onChange({ field: name, value });
                break;
        }
    };

    const onKeyPress = (e) => {
        if ((dataType === 'num' || dataType === 'phone')
            && (e.charCode < 48 || e.charCode > 57)
            && ((e.charCode !== 118 || e.charCode !== 120 || e.charCode !== 97) && !e.metaKey)) { // ASCII Key Char Codes of 0-9.    
            e.preventDefault();
        }
    };

    const onCapture = (e) => {
        if (dataType === 'password') {
            e.preventDefault();
        }
        else if ((dataType === 'num' || dataType === 'phone') && (!isValidNumber(e.target.value))) {
            e.preventDefault();
        }
    };

    return (
        <TextField
            {...rest}
            id={fieldName} name={fieldName} label={fieldLabel}
            type={dataType} value={fieldValue} placeholder={placeholder}
            variant='outlined' margin={textFieldMargin} fullWidth
            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
            inputProps={{ maxLength }}
            multiline={isMultiLine ? true : false}
            rows={rows}
            maxRows={maxRows}
            size="small"
            disabled={isDisabled ? true : false}
            onKeyPress={(e) => onKeyPress(e)}
            onPasteCapture={(e) => onCapture(e)}
            onDropCapture={(e) => onCapture(e)}
            onCopyCapture={(e) => onCapture(e)}
            {...formRegister(fieldName, onChange ? {
                onChange: (e) => onFieldChange(e),
                onBlur: (e) => onFieldChange(e)
            } : {})}
        />

    );
}