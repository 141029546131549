export const NonSGLoanApplicationDocument = Object.freeze({
    BANK_STATEMENTS: 'BankStatements',
    FINANCIAL_STATEMENTS: 'FinancialStatements',
    GST_STATEMENTS: 'GSTStatements',
    BUSINESS_REGISTRATION_CERTIFICATE: 'BusinessRegistrationCertificate',
    REVENUE_FINANCING: 'RevenueFinancing',
    DIRECTOR1_PASSPORT: 'Director1Passport',
    DIRECTOR1_UTILITY_BILLS: 'Director1UtilityBills',
    DIRECTOR2_PASSPORT: 'Director2Passport',
    DIRECTOR2_UTILITY_BILLS: 'Director2UtilityBills',
});
