import { Box } from "@mui/material";
import React from "react";

const Footer = () => {
    return (
        <Box
            sx={{
                background: "linear-gradient(266.33deg, #D5037D 3.04%, #760371 16.97%, #190553 45.49%)",
                height: 35
            }}
        />
    );
};

export default Footer;