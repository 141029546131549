import { SvgIcon } from "@mui/material";
import React from "react";

const CheckSvg = () => {
  return (
    <SvgIcon
      sx={{ scale: "4", mb: "2rem" }}
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="80" height="80" rx="40" fill="#26C70B" fillOpacity="0.1" />
      <mask
        id="mask0_11425_106487"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="10"
        y="10"
        width="60"
        height="60"
      >
        <rect x="10" y="10" width="60" height="60" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_11425_106487)">
        <path
          d="M36.5 51.5L54.125 33.875L50.625 30.375L36.5 44.5L29.375 37.375L25.875 40.875L36.5 51.5ZM40 65C36.5417 65 33.2917 64.3433 30.25 63.03C27.2083 61.7183 24.5625 59.9375 22.3125 57.6875C20.0625 55.4375 18.2817 52.7917 16.97 49.75C15.6567 46.7083 15 43.4583 15 40C15 36.5417 15.6567 33.2917 16.97 30.25C18.2817 27.2083 20.0625 24.5625 22.3125 22.3125C24.5625 20.0625 27.2083 18.2808 30.25 16.9675C33.2917 15.6558 36.5417 15 40 15C43.4583 15 46.7083 15.6558 49.75 16.9675C52.7917 18.2808 55.4375 20.0625 57.6875 22.3125C59.9375 24.5625 61.7183 27.2083 63.03 30.25C64.3433 33.2917 65 36.5417 65 40C65 43.4583 64.3433 46.7083 63.03 49.75C61.7183 52.7917 59.9375 55.4375 57.6875 57.6875C55.4375 59.9375 52.7917 61.7183 49.75 63.03C46.7083 64.3433 43.4583 65 40 65Z"
          fill="#26C70B"
        />
      </g>
    </SvgIcon>
  );
};

export default CheckSvg;
