import { Box, Card, Stack, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import CheckSvg from "./check-svg";
import AlertSvg from "./alert-svg";

const innerCardStyle = {
  display: "grid",
  gridTemplateColumns: "fit-content(8rem) 1ch 1fr",
  p: "1.5rem 3rem",
  whiteSpace: "nowrap",
  height: "fit-content",
  "& > span": {
    width: "8rem",
    height: "1rem",
  },
  "& > p": {
    fontWeight: 600,
    ml: 1,
    mb: 0,
    whiteSpace: "normal",
    height: "fit-content",
    flexGrow: 1,
    width: "100%",
    "&:first-of-type": {
      mb: 1,
    },
  },
  "&::before": {
    content: "none",
  },
};

/**
 * @typedef {Object} EnvelopeDetails
 * @property {string} name - The name of the director.
 * @property {string} email - The email of the director.
 */

const DocuSign = () => {
  const [searchParams] = useSearchParams();
  const error = searchParams.get("error");
  const name = searchParams.get("name");
  const email = searchParams.get("email");

  return (
    <Box>
      {!error && name && email ? (
        <Box
          display={"grid"}
          sx={{
            placeItems: "center",
            minHeight: "100dvh",
          }}
        >
          <Card
            sx={{
              p: "5rem 2rem",
              width: "40rem",
            }}
          >
            <Stack
              height="100%"
              gap={"15px"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <CheckSvg />
              <Typography fontWeight="600" fontSize="2rem">
                Submitted Successfully!
              </Typography>
              <Typography fontSize="1.25rem">
                Thank you for eSigning.
              </Typography>
              <Card borderRadius="6px" width="100%" sx={innerCardStyle}>
                <span>Director Name</span>:<p>{name || "N/A"}</p>
                <span>Email Address</span>:<p>{email || "N/A"}</p>
              </Card>

              <Typography mt={2}>
                Please reach out to{" "}
                <a href="mailto:loans@credilinq.ai">loans@credilinq.ai</a>, if
                you have any queries.
              </Typography>
            </Stack>
          </Card>
        </Box>
      ) : (
        <Box
          display={"grid"}
          sx={{
            placeItems: "center",
            minHeight: "100dvh",
          }}
        >
          <Card
            sx={{
              p: "5rem 2rem",
              width: "40rem",
            }}
          >
            <Stack
              height="100%"
              gap={"15px"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <AlertSvg />
              <Typography fontWeight="600" fontSize="2rem" fontFamily={""}>
                Something went wrong.
              </Typography>
              <Typography fontSize="1.25rem">
                {error || "Invalid Request"}
              </Typography>

              <Typography mt={2}>
                Please reach out to{" "}
                <a href="mailto:loans@credilinq.ai">loans@credilinq.ai</a>, if
                you have any queries.
              </Typography>
            </Stack>
          </Card>
        </Box>
      )}
    </Box>
  );
};
export default DocuSign;
